import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Button,
  TextField,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  Divider,
} from "@material-ui/core";
import "react-datepicker/dist/react-datepicker.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DatePicker from 'react-datepicker';
// import { addDays } from '@date-io/date-fns';
// import { addDays } from "date-fns";

const TeamDeadline = (props) => {
  const {
    TeamModalState,
    handleTeamModalClose,
    handleTime,
    selectedDate,
    handleProceed,
  } = props;

  useEffect(() => {
    console.log("teamDeadline", props);
  }, [props]);

  return (
    <>
      <Modal open={TeamModalState} onClose={handleTeamModalClose}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "10px",
            outline: "none",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            width: "320px",
            height: "400px",
            overflow: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "5px",
            }}
          >
            <Typography variant="h6" style={{ marginBottom: "10px" }}>
              Select DeadLine
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "5px",
            }}
          >
            <div>
              {/* <TextField
                id="date-picker"
                type="date"
                value={selectedDate || ""}
                onChange={(e) => handleTime(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    height: "30px",
                    boxShadow: "1px 1px 4px -2px #000000",
                  },
                }}
              /> */}
              <DatePicker
                selected={selectedDate || ""}
                onChange={handleTime}
                dateFormat="yyyy-MM-dd"
                placeholderText="yyyy-mm-dd"
                // minDate={new Date()}
                // maxDate={addDays(new Date())}
                customInput={
                  <TextField
                    label="Select Date"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  // style={{ width: 400}}
                  />}
              />
            </div>

            <div
              style={{
                marginLeft: "5px",
              }}
            >
              <Button
                onClick={() => {
                  handleProceed();
                }}
                style={{ backgroundColor: "#1976D2", color: "white", border: "none", outline: "none" }}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export const ReportModal = (props) => {
  const { open, close, reportData, workflow } = props;

  useEffect(() => {
    console.log("ReportModal", props);
  }, [props]);

  return (
    <>
      <Modal open={open} onClose={close}>
        <div
          style={{
            position: "absolute",
            // border: "5px solid red",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "10px",
            outline: "none",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            width: "90%",
            height: "fit-content",
            // maxHeight: "90vh",
            // Set max height to enable vertical scroll in smaller screens
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h4
              style={{
                color: "#007BFF",
              }}
            >
              Report & Analytics
            </h4>
            <IconButton onClick={close}>
              <CloseIcon style={{ color: "red" }} />
            </IconButton>
          </div>
          <div
            style={{
              width: "100%",
              height: "500px",
              overflow: "auto", // Enables both horizontal and vertical scroll
            }}
          >
            <TableContainer
              component={Paper}
              style={{
                backgroundColor: "white",
                width: "100%",
              }}
              id="table-to-pdf"
            >
              <Table aria-label="simple table" style={{ tableLayout: "fixed", minWidth: "600px" }}>
                <TableHead style={{ backgroundColor: "#1976d2" }}>
                  <TableRow>
                    <TableCell align="center" style={{ color: "white", width: "5%" }}>No</TableCell>
                    <TableCell align="center" style={{ borderLeft: "1px solid #ccc", borderRight: "1px solid #ccc", color: "white", width: "20%" }}>Asset</TableCell>
                    <TableCell align="center" style={{ borderRight: "1px solid #ccc", color: "white", width: "20%" }}>Workflow</TableCell>
                    <TableCell align="center" style={{ borderRight: "1px solid #ccc", color: "white", width: "15%" }}>Stage</TableCell>
                    <TableCell align="center" style={{ borderRight: "1px solid #ccc", color: "white", width: "15%" }}>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reportData?.map((row, i) => (
                    <TableRow key={row._id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell align="center" style={{ borderRight: "1px solid #ccc" }}>
                        {i + 1}
                      </TableCell>
                      <TableCell align="center" style={{ borderRight: "1px solid #ccc" }}>
                        {row.opportunityid?.title}
                      </TableCell>
                      <TableCell align="center" style={{ borderRight: "1px solid #ccc" }}>
                        {workflow &&
                          workflow?.map((data) => {
                            if (data._id === row.workflowId) {
                              return data.workflowName;
                            }
                          })
                        }
                      </TableCell>
                      {row.status === "Complete" || row.status === "Cancel" ? (
                        <>
                          <TableCell align="center" style={{ borderRight: "1px solid #ccc" }}>-</TableCell>
                          <TableCell align="center" style={{ borderRight: "1px solid #ccc" }}>{row.status}</TableCell>
                        </>
                      ) : (
                        <>
                          {row.teamDetails?.some((data) => data.status === "In Progress") ? (
                            row.teamDetails.map((data, index) => (
                              data.status === "In Progress" && (
                                <React.Fragment key={index}>
                                  <TableCell align="center" style={{ borderRight: "1px solid #ccc" }}>
                                    {data.teamName}
                                  </TableCell>
                                  <TableCell align="center" style={{ borderRight: "1px solid #ccc" }}>
                                    {data.status}
                                  </TableCell>
                                </React.Fragment>
                              )
                            ))
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </Modal>


    </>
  );
};
export default TeamDeadline;
