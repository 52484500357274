import React, { useState, useEffect, useRef } from "react";
import { Popover, OverlayTrigger, Button } from "react-bootstrap";
import Loader from "../utils/loader";
import { toast, ToastContainer } from "react-toastify";
import userService, { updateuserdata } from "../services/userService";
import { ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { withRouter } from "react-router-dom";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import team from "../static/noteams.png";
import DataGridMui from "./ReusableComponents/DataGridMui";
import adminServices from "../services/adminServices";
import SelectDropdownMui from "./ReusableComponents/SelectDropdownMui";
import SaveIcon from "@mui/icons-material/Save";
import MultiSelectDropdownWithSearch from "./ReusableComponents/SelectMultiDropdownMui";
// import Button from 'react-bootstrap/Button';
import { getappfeaturesrole } from "../services/appfeaturesrole";
import "../components/pages/SubAdminManageUser2.css";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { MultiSelect } from "primereact/multiselect";
import "primereact/resources/themes/saga-blue/theme.css"; // Theme
import "primereact/resources/primereact.min.css"; // Core CSS
import "primeicons/primeicons.css";
import { Modal, Dropdown } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import Select from 'react-select';
import { showToast } from "../messages";
import {
  FormControl, Dialog,
  DialogContent,
  Typography
} from '@material-ui/core'
import { getAssetsByOrg, addTeam, removeFromTeam, getRolesOfSelectedWorkflow, getAssetsOfSelectedWorkflow, getWorkflowListByOrg } from '../services/assetService'
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { TextField } from '@mui/material';
import CheckboxMui from "./ReusableComponents/CheckBoxMui";


const SubAdminManageUser = ({ currentUser }) => {
  const [loading, setLoading] = useState(true);
  const [teamMembers, setTeamMembers] = useState([]);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [adminModalShow, setAdminModalShow] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [filterTeam, setFilterTeam] = useState([]);
  const [adminChanged, setAdminChanged] = useState(false);
  const [users, setusers] = useState([]);
  const [userRole, setUserRole] = useState();
  const [userRoleGroup, setUserRoleGroup] = useState([]);
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [rolesLoading, setRolesLoading] = useState(true);
  const [loadedRoles, setLoadedRoles] = useState([]); // State to store the fetched roles
  const [rolesByOrgid, setRolesByOrgid] = useState([]);
  const [defaultRoles, setDefaultRoles] = useState([]);
  const [role, setRole] = useState("");
  const [selectedValues, setSelectedValues] = useState([]);
  const [teamRoles, setTeamRoles] = useState([]);
  const [nonTeamRoles, setNonTeamRoles] = useState([]);
  const [selectedRoleOption, setSelectedRoleOption] = useState(null);

  const [teamModalOpen, setTeamModalOpen] = useState(false);
  const [assetData, setAssetData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [roleOptions, setRoleOptions] = useState([]);
  const [workflowOptions, setWorkflowOptions] = useState([]);
  const [selectedWorkflowOption, setSelectedWorkflowOption] = useState(null);
  const [isAllTeam, setIsAllTeam] = useState(false)
  const [allRoles, setAllRoles] = useState([])
  const sidebarOpen = useSelector((state) => state.sideNavbar.isOpen.stateValue);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const sideBarData = useSelector(state => state.sideNavbar)
  const isSidebarOpen = sideBarData?.isOpen?.stateValue
  console.log(isSidebarOpen, "__isSidebarOpen")
  const [searchTerm, setSearchTerm] = useState('');

  // Update windowWidth on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const mobileView = windowWidth < 960

  let screenName = "User Management";
  const orgId = sessionStorage.getItem("organizationId")

  const features = JSON.parse(sessionStorage.getItem("FeatureRoleAccess"));
  const featureArray = features?.featuresAccess;

  // console.log(featureArray,"featureArray");
  let accessFeatures;
  if (featureArray) {
    const matchingFeature = featureArray.filter(feature => feature.ScreenName == screenName);

    if (matchingFeature) {
      accessFeatures = matchingFeature[0].features
      // setAccessTofeature(accessFeatures)
      // console.log("accessFeatures", accessFeatures);
    } else {
      console.log("No feature found with screen name:", screenName);
    }
  } else {
    console.log("No features found in the storage.");
  }

  const exportToExcel = () => {
    const dataToExport = users.filter((data) => data.username !== "Unknown").map((user) => ({
      "User Name": user.username,
      Email: user.email,
      "Phone Number": user.phone,
      "Role group": user.Role,
      "Assigned Roles": user.userRoles ? user.userRoles.join("\n") : "",
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Users");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const excelData = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(excelData, "users.xlsx");
  };

  const exportToCSV = () => {
    const csvContent =
      "User Name,Email,Phone Number,Role group,Assigned Roles\n" +
      users.filter((data) => data.username !== "Unknown")
        .map((user) => {
          return `${user.username},${user.email},${user.phone},${user.Role || ""
            },"${user.userRoles ? user.userRoles.join("\n") : ""}"`;
        })
        .join("\n");

    const csvData = new Blob([csvContent], { type: "text/csv" });
    saveAs(csvData, "users.csv");
  };


  const handleSelectAllTeam = (e) => {
    // const isAllTeam = e.target.checked;
    console.log("handleSelectAllTeam", e, "target", e.target)
    setIsAllTeam(e.target.checked);
  };

  const handleExport = (format) => {
    let featurename = "Export"
    const checkfeatureName = accessFeatures?.filter(k => k.name == featurename)
    console.log(checkfeatureName[0].access, "checkfeatureName")
    if (!checkfeatureName[0].access) {
      showToast(`Error.upload`)
    } else {
      if (format === "excel") {
        exportToExcel();
      } else if (format === "csv") {
        exportToCSV();
      }
    }

  };
  // console.log(sampleData, "sampleData");
  // console.log(defaultSampleData, "defaultSampleData");
  const sampleData = rolesByOrgid.map((role) => ({
    label: role.rolename,
    value: role.rolename.toLowerCase().replace(/\s+/g, ""),
  }));
  const defaultSampleData = defaultRoles.map((role) => ({
    label: role.rolename,
    value: role.rolename.toLowerCase().replace(/\s+/g, ""),
  }));

  const handleOptionChange = (event, newValue) => {
    console.log("NewVal  ", newValue[0].value);
    const newValuesToAdd = newValue.map((item) => item.value);

    // Check for duplicates before adding new values
    const uniqueNewValues = newValuesToAdd.filter(
      (value) => !selectedValues.includes(value)
    );

    setSelectedValues([...selectedValues, ...uniqueNewValues]);
    console.log("selected values  ", selectedValues);
    console.log(newValue, event, "select");
  };

  console.log(users, "getusers");
  const finalUserRole = users.map((k) => k.Role);
  const finalUserRoleGroup = users.map((k) => k.userRole);
  console.log(finalUserRole, finalUserRoleGroup, "firstt");

  useEffect(() => {
    if (adminChanged) {
      history.push({
        pathname: `/`,
      });
    }
  }, [adminChanged]);

  useEffect(() => {
    getOrganizationUsers();
  }, []);
  const email = sessionStorage.getItem("currentUserEmail");
  console.log(email, "Email");

  // const fetchRoles = async () => {
  //   try {
  //     const orgId = sessionStorage.getItem("organizationId");
  //     const data=orgId;
  //     const response = await getappfeaturesrole({data});
  //     console.log(response,"responsechangerole")
  //     const apiRoles = response.data.map((role) => ({
  //       value: role.rolename,
  //       name: role.rolename,
  //     }));
  //     // const hardcodedRoles = [
  //     //   {
  //     //     value: "User",
  //     //     name: "User",
  //     //   },
  //     //   {
  //     //     value: "System Admin",
  //     //     name: "System Admin",
  //     //   },
  //     //   {
  //     //     value: "Application Admin",
  //     //     name: "Application Admin",
  //     //   },
  //     // ];
  //     // Combine hardcoded roles with API roles
  //     // const mergedRoles = [...hardcodedRoles, ...apiRoles];
  //     setRolesByOrgid(response.data);
  //     setLoadedRoles(apiRoles);
  //     setRolesLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching roles:", error);
  //     setRolesLoading(false);
  //   }
  // };


  const fetchRoles = async () => {
    try {
      const orgId = sessionStorage.getItem("organizationId");
      const data = orgId;
      const response = await getappfeaturesrole({ data });
      console.log(response, "responsechangerole");

      // const apiRoles = response.data.map((role) => ({
      //   value: role.rolename,
      //   name: role.rolename,
      // }));

      const teamRoles = response.data.filter((role) => role.rolegroup === "Given");
      const nonTeamRoles = response.data

      // setTeamRoles(teamRoles);
      setNonTeamRoles(response.data);
      const nonTeamRolesMap = nonTeamRoles.map((role) => ({
        value: role.rolename,
        name: role.rolename,
      }));

      setRolesByOrgid(nonTeamRoles);
      setLoadedRoles(nonTeamRolesMap);
      setRolesLoading(false);

      console.log(nonTeamRolesMap, "nonTeamRolesMap")
    } catch (error) {
      console.error("Error fetching roles:", error);
      setRolesLoading(false);
    }
  };
  console.log(teamRoles, "teamRoles")
  console.log(nonTeamRoles, "nonTeamRoles")

  useEffect(() => {
    fetchRoles();
  }, []);

  const saveUserRole = async (params) => {
    const { email, selectedValues } = params.row;
    const data = { email, roleValues: selectedValues };
    console.log(params.row, "savedata")
    try {
      let featurename = "Edit"
      const checkfeatureName = accessFeatures?.filter(k => k.name == featurename)
      console.log(checkfeatureName[0].access, "checkfeatureName")
      if (!checkfeatureName[0].access) {
        showToast('Error.edit')
      } else {
        const response = await updateuserdata(data);
        if (response.status === 200) {
          showToast(`SubadminManageuser2.updateUserdata`);
        } else {
          toast.error(response.data.message);
        }
      }

    } catch (error) {
      console.error("Error updating user role:", error);
      showToast(`SubadminManageuser2.updateUserdataFail`);
    }
  };
  const getuserdata = async () => {
    try {
      let data = email;
      console.log(data, "dataemail");
      const response = await userService.getuserdata(data);
      console.log(response.userdata, "responsecheck");
      setusers(response?.userdata);

      console.log(response.userdata[0].Role, "gettt");

      setRole(response.userdata[0].Role);
      console.log(response.userdata, "Data");
      console.log(response.userdata.userRole, "gettt");
      let finalSelectedRoles = response.userdata.map((role) => {
        let selecteduserroles = {
          value: role.userRole,
          name: role.userRole,
        };
        return selecteduserroles;
      });
      const userRoles = response.userdata.map(user => user.userRole).flat();
      setSelectedValues(userRoles);
      const initialUsers = response.userdata.map(user => ({
        ...user,
        selectedValues: user.userRole // Initialize selectedValues as an empty array for each user
      }));
      setusers(initialUsers);
    } catch (error) {
      console.log(error);
    }
  };


  const getuserdataByOrgid = async () => {
    try {
      let data = email;
      console.log(data, "dataemail");
      const response = await userService.getuserdataByOrgid(data);
      console.log(response, "buorgid");
      setRolesByOrgid(response?.data);
      setDefaultRoles(response?.defaultdata);
      console.log(response.data, " ");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getuserdata();
    getuserdataByOrgid();
  }, [email]);

  const getOrganizationUsers = async () => {
    let currentUserEmail = sessionStorage.getItem("currentUserEmail");
    try {
      const response = await adminServices.getAllOrganizationUsers(
        currentUserEmail
      );
      console.log("organizationUsers", response);
      if (response.status === 200) {
        setTeamMembers(response.data);
        setFilterTeam(response.data);
        setLoading(false);
      } else if (response.status === 404) {
        setLoading(false);
        setTeamMembers(response.data);
        setFilterTeam(response.data);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching team:", error);
      setLoading(false);
    }
  };
  const handleRoleChange = (e, row) => {
    const updatedUsers = users.map((user) => {
      if (user.email === row.email) {
        console.log(user.email, row.email, e.selectedOption.value, user, "emails")
        return { ...user, selectedValues: e.value };
      }
      console.log(user, "setUpdatedusre")
      return user;
    });
    setusers(updatedUsers);
  };
  //  Get All Team Members
  // const getTeam = async () => {
  //     try {
  //         const teamMembers = await userService.getTeam();
  //         if (teamMembers.status === 200) {
  //             setTeamMembers(teamMembers.data.user);
  //             setFilterTeam(teamMembers.data.user);
  //             setLoading(false);
  //         } else if (teamMembers.status === 404) {
  //             setLoading(false);
  //             setTeamMembers(teamMembers.data.user);
  //             setFilterTeam(teamMembers.data.user);
  //         } else {
  //             setLoading(false);
  //         }
  //     } catch (error) {
  //         console.error("Error fetching team:", error);
  //         setLoading(false);
  //     }
  // };

  // Delete Member
  // const handleDelete = async (user) => {
  //     setLoading(true);

  //     try {
  //         const team = await userService.deleteTeam({ id: user.id });
  //         if (team.status !== 200) {
  //             toast.error(team.message);
  //         } else {
  //             toast.success(team.message);
  //             getTeam();
  //         }
  //     } catch (error) {
  //         console.error("Error deleting user:", error);
  //     } finally {
  //         setLoading(false);
  //     }
  // };

  // const handleSearch = (e) => {
  //     if (e.target.value === "") {
  //         setFilterTeam(teamMembers);
  //     } else {
  //         const value = e.target.value.toLowerCase();

  //         const result = teamMembers.filter((member) =>
  //             member.name.toLowerCase().includes(value)
  //         );

  //         setFilterTeam(result);
  //     }
  // };

  const handleRole = (e) => {
    console.log("eData_", e);

    setRole(e.target.value);
  };

  //manage team


  const toggleTeamModal = (rowData) => {
    let featurename = "Edit"
    const checkfeatureName = accessFeatures?.filter(k => k.name == featurename)
    console.log(checkfeatureName[0].access, "checkfeatureName")
    if (!checkfeatureName[0].access) {
      showToast('Error.edit')
    } else {
      setSelectedRowData(rowData);
      setTeamModalOpen(true);
      handleGetWorkflowListByOrg()
    }
  };
  // console.log(selectedRowData, "selectedRowData")

  const handleGetWorkflowListByOrg = async () => {
    try {
      const orgId = sessionStorage.getItem("organizationId");
      const data = { orgId };
      const response = await getWorkflowListByOrg(data);
      if (response.status === 200) {
        // console.log(response.workflowList, "__Workflow list");
        const options = response.workflowList.map(workflow => ({
          value: workflow._id,
          label: workflow.workflowName
        }));
        setWorkflowOptions(options);
      } else {
        showToast(`SubadminManageuser2.fetchWorkflowListFail`);
      }
    } catch (error) {
      console.error("Error in fetching workflow list:", error);
      showToast(`SubadminManageuser2.fetchWorkflowListFail`);
    }
  };
  // console.log(workflowOptions, "workflowOptions")

  const handleSelectWorkflowChange = async (selectedOption) => {
    const selectedValue = selectedOption?.value;
    console.log("Selected workflow value:", selectedValue);

    setSelectedWorkflowOption(selectedValue);
    setRoleOptions([]);
    setSelectedRoleOption(null);
    setAssetData([]);
    handleGetRolesOfSelectedWorkflow(selectedValue)
  };
  // console.log(selectedWorkflowOption, "__selectedWorkflowOption")

  const handleGetRolesOfSelectedWorkflow = async (workflowId) => {
    // console.log(workflowId, "selectedWorkflowOption__")
    try {
      const data = {
        workflowId: workflowId
      }
      const response = await getRolesOfSelectedWorkflow(data);
      if (response.staus === 200) {
        // console.log(response, "__stageList")
        const options = response.stageList.map(list => ({
          value: list._id,
          label: list.stageName
        }));
        let arr = []
        options.map((obj) => {
          arr.push(obj.value)
        })
        setAllRoles(arr)
        console.log(options, "__options")
        setRoleOptions(options);
      } else {
        showToast(`SubadminManageuser2.fetchWorkflowRoleListFail`);
      }
    } catch (error) {
      console.error("Error in fetching workflow role list:", error);
      showToast(`SubadminManageuser2.fetchWorkflowRoleListFail`);
    }
  };
  // console.log(workflowOptions, "workflowOptions")



  useEffect(() => {
    if (roleOptions?.length > 0 && !selectedRoleOption) {
      setSelectedRoleOption(roleOptions[0].value);
      fetchAssetData(selectedRowData, roleOptions[0].value);
    }
  }, [roleOptions]);

  // console.log(roleOptions, "roleOptions");

  const handleCloseModal = async () => {
    setTeamModalOpen(false);
  }
  // const selectedOption = roleOptions?.find(option => option.value === selectedRoleOption);

  function toggleCheckbox() {
    let checkbox = document.getElementById("isAllTeam");
    checkbox.checked = !checkbox.checked; // Toggle checkbox state
  }

  const handleSelectChange = async (selectedOption) => {
    const selectedValue = selectedOption?.value;
    console.log("Selected value:", selectedValue);

    setSelectedRoleOption(selectedValue);
    if (isAllTeam) {
      toggleCheckbox()
      setIsAllTeam(false)
    }
    fetchAssetData(selectedRowData, selectedValue);
  };

  const fetchAssetData = async (rowData, selectedValue) => {
    // console.log(selectedWorkflowOption,"++selectedWorkflowOption")
    setLoading(true);
    try {
      const dataToSend = {
        workflowId: selectedWorkflowOption,
        orgId,
        email: rowData.email,
        teamName: selectedValue
      };
      const response = await getAssetsByOrg(dataToSend);
      // console.log(response.data, "response++")
      if (response.status === 200) {
        setAssetData(response.data);
      } else {
        showToast('SubadminManageuser2.fetchAssetDataFail');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching asset data:', error);
      showToast('SubadminManageuser2.fetchAssetDataFail');
      setLoading(false);
    }
  };


  const addUserToTeam = async (assetId) => {
    setLoading(true);
    try {
      const dataToSend = {
        assetId,
        email: selectedRowData.email,
        teamName: isAllTeam ? allRoles : selectedRoleOption
      };
      const response = await addTeam(dataToSend);
      // console.log(response.data, "responseofadd")
      if (response.status === 200) {
        await fetchAssetData(selectedRowData, selectedRoleOption);
        toast.success(`User successfully added to ${Array.isArray(dataToSend.teamName) ? "all teams" : response.getTeamName}.`);


      } else {
        showToast(`SubadminManageuser2.addUserTeamFail`);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error in adding user to team.', error);
      showToast(`SubadminManageuser2.addUserTeamFail`);
      setLoading(false);
    }

  };

  const removeUserFromTeam = async (assetId) => {
    setLoading(true);
    try {
      const dataToSend = {
        assetId,
        email: selectedRowData.email,
        teamName: selectedRoleOption
      };
      // console.log(dataToSend, "__dataToSend")
      const response = await removeFromTeam(dataToSend);
      // console.log(response.data, "responseofremove")
      if (response.status === 200) {
        await fetchAssetData(selectedRowData, selectedRoleOption);
        toast.success(`User successfully removed from ${response.getTeamName}.`);
      } else {
        showToast(`SubadminManageuser2.removeUserTeamFail`);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error in removing user from team.', error);
      showToast(`SubadminManageuser2.removeUserTeamFail`);
      setLoading(false);
    }
  };

  const handleCloseTeamModal = () => {
    setTeamModalOpen(false);
    setSelectedRoleOption(null)
    setSelectedRowData(null)
    setAssetData([])
    setRoleOptions([])
    setWorkflowOptions([])
    setSelectedWorkflowOption(null)
    setSearchTerm('')
  };

  console.log(assetData, "assetData")
  // console.log(selectedRoleOption, "selectedRoleOption")
  // console.log(selectedRowData, "selectedRowData")

  // const columns = [
  //   {
  //     field: "username",
  //     headerName: "User Name",
  //     // flex: 0.3,
  //     editable: false,
  //     width: !mobileView && !sidebarOpen ? 240 : 180
  //   },
  //   {
  //     field: "email",
  //     headerName: "Email",
  //     type: "text",
  //     // flex: 0.6,
  //     width: !mobileView && !sidebarOpen ? 250 : 200
  //   },
  //   {
  //     field: "phone",
  //     headerName: "Phone Number",
  //     type: "number",
  //     // flex: 0.3,
  //     headerAlign: "left",
  //     align: "left",
  //     width: !mobileView && !sidebarOpen ? 180 :130
  //   },




  //   {
  //     field: "selectRole",
  //     headerName: "Roles",
  //     // flex: 0.8,
  //     width: !mobileView && !sidebarOpen ? 460 : 470,
  //     renderCell: (params) => {
  //       console.log(params.row.selectedValues, "roleparams");
  //       return (
  //         <div className="cell-container1">
  //           <div className="p-field">
  //             <MultiSelect
  //               className="custom-multiselect"
  //               value={params.row.selectedValues}
  //               options={loadedRoles}
  //               onChange={(e) => handleRoleChange(e, params.row)}
  //               optionLabel="name"
  //               optionValue="value"
  //               placeholder="Select Roles"
  //             />
  //           </div>
  //           <div className="button-container1">
  //             <Button
  //               variant="primary"
  //               onClick={() => toggleTeamModal(params.row)}
  //             >
  //               Manage Team
  //             </Button>
  //           </div>
  //         </div>
  //       );
  //     }
  //   },




  //   {
  //     field: "delete",
  //     headerName: "Delete",
  //     // flex: 0.1,
  //     width: !mobileView && !sidebarOpen ? 140 : 100,
  //     renderCell: (params) => {
  //       // console.log("params", params)
  //       return (
  //         <button
  //           className="btn btn-md mx-auto"
  //           onClick={() => deleteUser(params)}
  //         // disabled={params.row.isApproved == true ? true : false}
  //         >
  //           {<DeleteRoundedIcon fontSize="small" style={{ color: "red" }} />}
  //         </button>
  //       );
  //     },
  //   },
  //   {
  //     field: "Save",
  //     headerName: "Save",
  //     // flex: 0.1,
  //     width: !mobileView && !sidebarOpen ? 130 : 80,
  //     renderCell: (params) => {
  //       return (
  //         <button
  //           className="btn btn-md mx-auto"
  //           onClick={() => saveUserRole(params)}
  //         >
  //           {<SaveIcon fontSize="small" style={{ color: "green" }} />}
  //         </button>
  //       );
  //     },
  //   },
  // ];


  const onShowSelectAll = () => {
    let selectAllCheckbox = document.querySelector(".p-multiselect-header .p-checkbox");

    if (selectAllCheckbox) {
      // Remove any existing label to prevent duplicates
      const existingLabel = document.querySelector(".p-multiselect-header span.select-all-label");
      if (existingLabel) {
        existingLabel.remove();
      }

      // Create and append the new label
      const selectAllLabel = document.createElement("span");
      selectAllLabel.innerText = " Select All";
      selectAllLabel.className = "select-all-label";
      selectAllCheckbox.parentNode.appendChild(selectAllLabel);
    }
  };

  const columns = [
    {
      field: "username",
      headerName: "User Name",
      flex: 0.1,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      type: "text",
      flex: 0.1,
    },
    {
      field: "phone",
      headerName: "Phone Number",
      type: "number",
      flex: 0.1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "selectRole",
      headerName: "Roles",
      width: 460,
      renderCell: (params) => {
        console.log(params?.row?.selectedValues, loadedRoles, "roleparams")
        return (
          <>
            <div className="p-field"
              style={{ width: "300px", overflowX: "auto" }}
            >
              <MultiSelect
                className="custom-multiselect"
                value={params?.row?.selectedValues?.filter(element =>
                  loadedRoles.some(item => item.name === element)
                )}
                // value={params.row.selectedValues?.filter((item) => item !== "null" && item !== undefined)}
                options={loadedRoles}
                onChange={(e) => handleRoleChange(e, params.row)}
                optionLabel="name"
                optionValue="value"
                placeholder="Select Roles"
                onShow={onShowSelectAll}
              />
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => toggleTeamModal(params.row)}
              style={{ marginLeft: "30px" }}
            >
              <p style={{ backgroundColor: "#1976D2", color: "white", padding: "5px", borderRadius: "4px", fontSize: "12px" }}>

                Manage Team
              </p>
            </Button>
          </>
        );
      },
    },

    {
      field: "delete",
      headerName: "Delete",
      flex: 0.1,
      renderCell: (params) => {
        // console.log("params", params)
        return (
          <button
            className="btn btn-md mx-auto"
            onClick={() => deleteUser(params)}
          // disabled={params.row.isApproved == true ? true : false}
          >
            {<DeleteRoundedIcon fontSize="small" style={{ color: "red" }} />}
          </button>
        );
      },
    },
    {
      field: "Save",
      headerName: "Save",
      flex: 0.1,
      renderCell: (params) => {
        return (
          <button
            className="btn btn-md mx-auto"
            onClick={() => saveUserRole(params)}
          >
            {<SaveIcon fontSize="small" style={{ color: "green" }} />}
          </button>
        );
      },
    },
  ];

  const deleteUser = (params) => {
    console.log("deleteUser function called");
    setCurrentRow(params.row);
    let featurename = "Delete"
    const checkfeatureName = accessFeatures?.filter(k => k.name == featurename)
    console.log(checkfeatureName[0].access, "checkfeatureName")
    if (!checkfeatureName[0].access) {

      showToast(`SubadminManageuser2.deleteUserTeamFail`)
    } else {
      setDeleteModalShow(prevState => !prevState);
    }

  };


  const deleteUserConfirm = async () => {
    const email = sessionStorage.getItem("currentUserEmail")
    let sendData = {
      email: currentRow.email,
      subAdminEmail: email,
    };
    setLoading(true);
    try {
      let response = await adminServices.subAdminDeleteUserDirectly(sendData);
      console.log("response", response);
      if (response.status == 200) {
        toast.success(response.message);
        setusers(prevUsers => prevUsers.filter(user => user.email !== currentRow.email));
      } else {
        toast.info(response.message);
      }
    } catch (e) {
      console.log("error", e);
    }
    getOrganizationUsers();
    setDeleteModalShow(false);
    setLoading(false);
  };

  const changeAdmin = (params) => {
    console.log("params", params);
    setCurrentRow(params.row);
    setAdminModalShow(!adminModalShow);
  };

  const changeAdminConfirm = async () => {
    let sendData = {
      email: currentRow.email,
      isTransfer: true,
    };
    setLoading(true);
    try {
      let response = await adminServices.subAdminTransferAdminRole(sendData);
      console.log("response", response);
      if (response.status == 200) {
        toast.success(response.message);
        setAdminChanged(true);
      } else {
        toast.info(response.message);
      }
    } catch (e) {
      console.log("error", e);
    }
    setAdminModalShow(false);
    // getOrganizationUsers()
    setLoading(false);
  };


  const styles = {
    container: {
      borderRadius: '10px',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '20px',
    },
    closeButton: {
      backgroundColor: 'transparent',
      border: 'none',
      color: 'red',
      fontSize: '20px',
      cursor: 'pointer',
    },
    infoRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '10px',
    },
    flexItems: {
      margin: "10px",
      // width: "20%",
      width: "auto",
    },
    label: {
      fontWeight: 'bold',
      marginRight: '10px',
      color: "#1976D2"
    },
    value: {
      flexGrow: 1,
      border: "1px solid lightgray",
      borderRadius: "4px",
      padding: "4px",
      fontWeight: "bold"
    },
    select: {
      flexGrow: 1,
      padding: '5px',
    },
    sectionTitle: {
      color: '#007bff',
      marginTop: '20px',
    },
    projectItem: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '10px',
    },
    projectActionButton: {
      backgroundColor: 'transparent',
      border: 'none',
      color: '#007bff',
      fontSize: '20px',
      cursor: 'pointer',
    },
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: "0.9rem"
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "0.9rem"
    }),
    singleValue: (provided) => ({
      ...provided,
      fontWeight: 'bold',
      fontSize: "0.9rem"
    }),
  };

  // search


  // Filter both assigned and other projects based on search term
  const filteredAssignedProjects = assetData.assignedProjects?.filter(project =>
    project.opportunityTitle.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredOtherProjects = assetData.otherProjects?.filter(project =>
    project.opportunityTitle.toLowerCase().includes(searchTerm.toLowerCase())
  );




  return (
    <div >
      <Loader loading={loading} />

      <div style={{ background: "#f0f0f7", paddingLeft: "5px" }}>
        <div className="row justify-content-end w-100 mb-3">
          <div style={{ marginRight: "15px" }}>
            <Dropdown>
              <Dropdown.Toggle variant="primary" id="dropdown-basic">
                Export
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => handleExport("excel")}
                  className="dropdown-item  custom-dropdown-item"
                >
                  Export to Excel
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleExport("csv")}
                  className="dropdown-item custom-dropdown-item"
                >
                  Export to CSV
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <div className="row mt-2 w-100">
          {users?.length === 0 ? (
            <div className="col-md-12 text-center">
              <img src={team} alt="No Teams" />
              <h3>No User Records found!</h3>
            </div>
          ) : (
            <>
              <div className="col-md-12" style={{ paddingLeft: windowWidth > 600 && !isSidebarOpen ? "55px" : "15px" }}>
                <DataGridMui
                  height="60vh"
                  getRowId={(data) => data._id}
                  columns={columns}
                  rows={users.filter(user => user.username !== "Unknown")}
                  checkboxSelection={false}
                  bgWhite={true}
                  manageWidth={true}
                />
              </div>
            </>
          )}
        </div>
      </div>

      {/* Delete User Modal */}
      {/* {deleteModalShow && (
                  <div className="custom-modal">
                      <div className="custom-modal-content">
                          <span className="close" onClick={() => setDeleteModalShow(false)}>&times;</span>
                          <h2>Delete User</h2>
                          <p>
                              Are You Sure ? <br />
                              You want to delete <strong>{currentRow?.username}</strong>
                          </p>
                          <div>
                              <div className="col-md-12">
                                  <button className="btn btn-primary hvr-float-shadow buysub" onClick={deleteUserConfirm}>Confirm</button>
                              </div>
                          </div>
                      </div>
                  </div>
              )} */}
      {deleteModalShow && (
        <div className="custom-modal">
          <div className="custom-modal-content">
            <span className="close" onClick={() => setDeleteModalShow(false)}>&times;</span>
            <h2>Delete Account</h2>
            <p>Do you really want to delete this user? The user will be deleted permanently.</p>
            <div className="modal-footer">
              <button className="btn btn-secondary" onClick={() => setDeleteModalShow(false)}>Cancel</button>
              <button className="btn btn-danger" onClick={deleteUserConfirm}>Delete</button>
            </div>
          </div>
        </div>
      )}


      <Modal
        isOpen={adminModalShow}
        toggle={() => setAdminModalShow(!adminModalShow)}
        style={{
          position: "absolute",
          left: "43%",
          top: "35%",
        }}
      >
        <ModalHeader toggle={() => setAdminModalShow(!adminModalShow)}>
          Change Admin
        </ModalHeader>
        <ModalBody>
          <div style={{ textAlign: "center" }}>
            <p>
              Are You Sure ? <br />
              You want to change <strong>{currentRow?.username}</strong> as
              Admin
            </p>

            <div>
              <div className="col-md-12">
                <button
                  className="btn btn-primary hvr-float-shadow buysub"
                  onClick={changeAdminConfirm}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      {teamModalOpen && (
        <Dialog
          open={teamModalOpen}
          onClose={handleCloseModal}
          PaperProps={{
            style: {
              minWidth: windowWidth < 425 ? '95%' : "80%",
              minHeight: '80%',
              maxWidth: '80vw',
              maxHeight: '90vh',
              // padding: "15px",
              borderRadius: "10px"
            },
          }}
        >
          <DialogContent
            PaperProps={{
              style: {
                width: '100%',
                height: '100%',
              }
            }}>
            <div style={styles.container}>
              <div style={styles.header}>
                <h2 style={{ color: "#1976D2" }}>Manage Team</h2>
                <HighlightOffIcon style={styles.closeButton} onClick={handleCloseTeamModal} />
              </div>

              <div style={{ display: "flex", justifyContent: "start", flexWrap: "wrap" }}>
                <div
                  style={styles.flexItems}
                >
                  <div style={styles.label}>User Name</div>
                  <div
                    // style={styles.value}
                    style={{ fontWeight: "bold", marginTop: "10px" }}
                  >{selectedRowData.username}</div>
                </div>
                <div
                  style={styles.flexItems}
                >
                  <div style={styles.label}>Select Workflow:</div>
                  <Select
                    options={workflowOptions}
                    value={workflowOptions.find(option => option.value === selectedWorkflowOption)}
                    onChange={handleSelectWorkflowChange}
                    placeholder="Select Workflow"
                    styles={customStyles}
                  />
                </div>
                {selectedWorkflowOption &&
                  <>
                    
                    <div style={styles.flexItems}>
                      <div style={styles.label}>Select Team:</div>
                      <FormControl style={{ minWidth: 'auto', marginRight: '8px' }}>
                        <Select
                          options={roleOptions}
                          value={roleOptions.find(option => option.value === selectedRoleOption)}
                          onChange={handleSelectChange}
                          placeholder="Select Role"
                          styles={customStyles}
                        />
                      </FormControl>
                    </div>
                    <div style={styles.flexItems}>
                      {/* <CheckboxMui
                        // label="Select All Team"
                        isChecked={isAllTeam}
                        onChange={handleSelectAllTeam}
                      /> */}
                      <label for="isAllTeam" style={{
                        fontWeight: 'bold',
                        marginRight: '10px',
                        color: "#1976D2",
                      }}>Select All Team</label><br />
                      <input id="isAllTeam" type="checkbox"
                        style={{ width: "22px", height: "22px" }}
                        value={isAllTeam} onChange={(e) => handleSelectAllTeam(e)} />
                    </div>
                  </>}
              </div>

              {/* search */}
              {selectedWorkflowOption && selectedRoleOption &&
                <div>
                  <TextField
                    label="Search Projects"
                    variant="outlined"
                    fullWidth
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{ margin: "10px 0px" }}
                  />
                </div>
              }

              {/* Assigned Projects */}
              <div style={{ marginTop: "10px", height: "400px", overflowY: "auto", }}>
                {selectedWorkflowOption && selectedRoleOption &&
                  <>
                    <Accordion defaultExpanded>
                      <div style={{ border: "1px solid #ccc", borderRadius: "5px" }}>
                        <AccordionSummary>
                          <Typography><b style={{ color: "#1976D2" }}>Assigned Projects</b></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {filteredAssignedProjects?.length > 0 ? (
                            filteredAssignedProjects.map((asset, index) => (
                              <div
                                key={asset._id}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  borderBottom: "1px solid #ccc"
                                }}
                              >
                                <p style={{ marginTop: "12px" }}>{index + 1}. {asset.opportunityTitle}</p>
                                <RemoveIcon
                                  onClick={() => removeUserFromTeam(asset._id)}
                                  style={{ cursor: "pointer", marginTop: "12px", color: "red" }}
                                />
                              </div>
                            ))
                          ) : (
                            <>
                              {searchTerm ? (
                                <p>No search result found.</p>
                              ) : (
                                <p>No assigned projects found.</p>
                              )}
                            </>
                          )}
                        </AccordionDetails>
                      </div>
                    </Accordion>

                    {/* Other Projects Accordion */}
                    <Accordion defaultExpanded>
                      <div style={{ border: "1px solid #ccc", borderRadius: "5px" }}>
                        <AccordionSummary>
                          <Typography><b style={{ color: "#1976D2" }}>Other Projects</b></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {filteredOtherProjects?.length > 0 ? (
                            filteredOtherProjects.map((asset, index) => (
                              <div
                                key={asset._id}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  borderBottom: "1px solid #ccc"
                                }}
                              >
                                <p style={{ marginTop: "12px" }}>{index + 1}. {asset.opportunityTitle}</p>
                                <AddIcon
                                  style={{ marginTop: "12px", cursor: "pointer", color: "rgb(61, 131, 250)" }}
                                  onClick={() => addUserToTeam(asset._id)}
                                />
                              </div>
                            ))
                          ) : (
                            <>
                              {searchTerm ? (
                                <p>No search result found.</p>
                              ) : (
                                <p>No other projects found.</p>
                              )}
                            </>
                          )}
                        </AccordionDetails>
                      </div>
                    </Accordion>

                  </>
                }
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}

    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SubAdminManageUser));