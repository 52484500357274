import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Container,
  TextField,
  Button,
  IconButton,
  Modal,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Menu,
  MenuItem,
  FormControl,
  FormControlLabel,
  Checkbox,
  Paper,
  Switch
} from "@material-ui/core";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import WindowIcon from "@mui/icons-material/Window";
import RefreshIcon from "@mui/icons-material/Refresh";
import FolderIcon from "@mui/icons-material/Folder";
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import LensIcon from "@mui/icons-material/Lens";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DownloadIcon from "@mui/icons-material/Download";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import AddIcon from "@mui/icons-material/Add";
import UploadIcon from "@mui/icons-material/CloudUploadOutlined";
import TableRowsIcon from "@mui/icons-material/TableRows";
import { Link } from "react-router-dom";
import { DocumentGridCard, DocumentListCard } from "./MyDocumentsCards";
import documentService from "../services/document";
import { ListItemText } from "@material-ui/core";
import { toast } from "react-toastify";
import { asBlob } from "html-docx-js-typescript";
import { saveAs } from "file-saver";
import html2pdf from "html2pdf.js";
import "./SearchOpportunity.css";
import SelectDropdownMui from "./ReusableComponents/SelectDropdownMui";
import opportunityService from "../services/opportunityService";
import federalorganizationsService from "../services/federalorganizationsService";
import MultiSelectDropdownWithSearch from "./ReusableComponents/MultiSelectDropDownMui";
import noticetypesService from "../services/noticetypesService";
import naicsService from "../services/naicsService";
import productnServiceCodeService from "../services/productnServiceCodeService";
import setasidesService from "../services/setasidesService";
import locationstateService from "../services/locationstateService";
import locationcountryService from "../services/locationcountryService";
import { limitList } from "../utils/pageLimit";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CloseIcon from "@mui/icons-material/Close";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import RemoveIcon from "@mui/icons-material/Remove";
import Loader from "../utils/loader";
import CircleIcon from '@mui/icons-material/Circle';
import ReactTooltip from "react-tooltip";
import { useSelector } from "react-redux";
import { showToast } from "../messages";
// import SearchOpportunityFilter from "./SearchOpportunityFilter";
let docList = [
  {
    _id: {
      $oid: "654b34066b055a3370d7b939",
    },
    documentName: "doc 1",
    tags: ["park", "car"],
    onlyDocumentContent:
      '{"blocks":[{"key":"82c9f","text":"Their going to the park theyre car is parked their.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
    version: [],
    drafts: [],
    date: {
      $date: "2023-11-08T07:09:10.177Z",
    },
    activeDoc: false,
    comments: [],
    ratings: 0,
    totalRatings: 0,
    readOnly: false,
    isUpdated: false,
    ownerName: "",
    ownerDP: "",
    sharedWith: [],
    pendingDocuments: [],
    mergeDocuments: [],
    reviewStatus: null,
    reviewDate: "",
    isLatestDocument: true,
    mergeStatus: false,
    documentAudit: [],
    imageUrl: [],
    editor: {
      $oid: "654b2f6f6b055a3370d7b8f9",
    },
    documentType: "document",
    month: 11,
    year: 2023,
    status: "original",
    __v: 0,
    characterCount: 51,
    wordsCount: 10,
  },
  {
    _id: {
      $oid: "654b34066b055a3370d7b939",
    },
    documentName: "doc 1",
    tags: ["park", "car"],
    onlyDocumentContent:
      '{"blocks":[{"key":"82c9f","text":"Their going to the park theyre car is parked their.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
    version: [],
    drafts: [],
    date: {
      $date: "2023-11-08T07:09:10.177Z",
    },
    activeDoc: false,
    comments: [],
    ratings: 0,
    totalRatings: 0,
    readOnly: false,
    isUpdated: false,
    ownerName: "",
    ownerDP: "",
    sharedWith: [],
    pendingDocuments: [],
    mergeDocuments: [],
    reviewStatus: null,
    reviewDate: "",
    isLatestDocument: true,
    mergeStatus: false,
    documentAudit: [],
    imageUrl: [],
    editor: {
      $oid: "654b2f6f6b055a3370d7b8f9",
    },
    documentType: "document",
    month: 11,
    year: 2023,
    status: "original",
    __v: 0,
    characterCount: 51,
    wordsCount: 10,
  },
];

const SearchOpportunity = () => {
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [listView, setListView] = useState(true);
  const [documents, setDocuments] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [downloadAnchorElDropdown, setDownloadAnchorElDropdown] =
    useState(null);

  const [myDocs, setMyDocs] = useState([]);
  const [deletedDocs, setDeletedDocs] = useState([]);
  const [sharedDocs, setSharedDocs] = useState([]);
  const [isFilterBlockVisible, setFilterBlockVisibility] = useState(true);
  const [isFedOrgFilter, setFedOrgFilter] = useState(false);
  const [isNoticeTypeFilter, setNoticeTypeFilter] = useState(false);
  const [isNaicsFilter, setNaicsFilter] = useState(false);
  const [isPronServiceFilter, setPronServiceFilter] = useState(false);
  const [isSetAsideFilter, setSetAsideFilter] = useState(false);
  const [isStateFilter, setStateFilter] = useState(false);
  const [isCountryFilter, setCountryFilter] = useState(false);
  const [isStatusFilter, setStatusFilter] = useState(false);
  const [initialMyDocs, setInitialMyDocs] = useState([]);
  const [initialDeletedDocs, setInitialDeletedDocs] = useState([]);
  const [initialSharedDocs, setInitialSharedDocs] = useState([]);
  const [opportunity, setOpportunity] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(25);
  const [totalPage, setTotalPage] = useState();
  const [fromPage, setFromPage] = useState(1);
  const [toPage, setToPage] = useState(25);
  const [results, setTotalReuslts] = useState();
  const [isFilterSideVisible, setIsFilterSideVisible] = useState(true);
  const [federalOrgData, setfederalOrgData] = useState([]);
  const [naicsData, setNaicsData] = useState([]);
  const [productnservicecodesData, setProductnservicecodesData] = useState([]);
  const [setasidesData, setsetasidesData] = useState([]);
  const [statesData, setstatesData] = useState([]);
  const [countriesData, setcountriesData] = useState([]);
  const [noticetypesData, setNoticetypesData] = useState([]);
  const [value, setValue] = useState({});
  const [limitValue, setLimitValue] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [selectedFilters, setSelectedFilters] = useState();
  const [selectedFiltersFederal, setSelectedFilterFederal] = useState([]);
  const [selectedFiltersNotice, setSelectedFilterNotice] = useState([]);
  const [selectedFiltersNaics, setSelectedFilterNaics] = useState([]);
  const [selectedFiltersPNS, setSelectedFilterPNS] = useState([]);
  const [selectedFiltersSetAside, setSelectedFilterSetAside] = useState([]);
  const [selectedFiltersState, setSelectedFilterState] = useState([]);
  const [selectedFiltersCountry, setSelectedFilterCountry] = useState([]);
  const [searchQuery, setSearchQuery] = useState([]);
  const [searchKeyList, setSearchKeyList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);
  const [countryState, setCountryState] = useState([]);
  const dropdownRef = useRef(null);
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const [sortBy, setSortBy] = useState('');
  const [showPastOpportunities, setShowPastOpportunities] = useState(false)
  const debounceTimeout = useRef(null);
  const isOpen = useSelector((state) => state.sideNavbar.isOpen.stateValue);
  console.log(isOpen, "redux isOpen")


  useEffect(() => {
    fetchData(filterOptions)
  }, [showPastOpportunities])

  const handleFilters = (param, param2) => {
    console.log("handleFiltersParams", param, param2)
  }

  const handlePastDocumentClick = async () => {
    if (showPastOpportunities == false) {
      setShowPastOpportunities(true)

    } else {
      setShowPastOpportunities(false)
    }
  }
  // useEffect(() => {
  //   if (sortBy) {
  //     updateCategoryStates(opportunity);
  //   }
  // }, [sortBy]);

  const handleSortChange = (event) => {


    const criterion = event.target.value;
    // alert(criterion)
    setSortBy(criterion);
    updateCategoryStates(opportunity, criterion); // Update category states based on current data and selected sort criterion
    // setDropdownVisible(false);
  };

  const updateCategoryStates = (opportunities, criterion) => {
    // alert(sortBy)
    let sortedOpportunities = opportunities;
    if (criterion === 'title') {
      // alert("updateCategoryStates")
      sortedOpportunities = sortOpportunitiesByTitle(opportunities);
    }
    else if (criterion === 'publishDate') {
      sortedOpportunities = sortOpportunitiesByPublishDate(opportunities);
    }

    else if (criterion === 'responseDate') {
      sortedOpportunities = sortOpportunitiesByResponseDate(opportunities);
    }

    else if (criterion === 'department') {
      sortedOpportunities = sortOpportunitiesByDepartment(opportunities);
    }

    else if (criterion === 'subtier') {
      sortedOpportunities = sortOpportunitiesBySubtier(opportunities);
    }

    else if (criterion === 'office') {
      sortedOpportunities = sortOpportunitiesByOffice(opportunities);
    }

    else if (criterion === 'noticeType') {
      sortedOpportunities = sortOpportunitiesBynoticeType(opportunities);
    }

    console.log(sortedOpportunities, "sortedOpportunities")
    setOpportunity(sortedOpportunities)
  };


  const handleIconClick = () => {
    setDropdownVisible(!dropdownVisible);
    if (dropdownRef.current) {
      dropdownRef.current.focus();
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    if (dropdownVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownVisible]);



  const sortOpportunitiesByTitle = (opportunities) => {
    console.log("sortOpportunitiesByTitle called with opportunities:", opportunities);
    // alert("sortOpportunitiesByTitle")
    return opportunities?.sort((a, b) => {
      const titleA = a.title || ''; // Default to empty string if title is not available
      const titleB = b.title || ''; // Default to empty string if title is not available
      console.log(titleA.localeCompare(titleB), "checksort")
      return titleA.localeCompare(titleB);
    });
  };

  const sortOpportunitiesByPublishDate = (opportunities) => {
    console.log("sortOpportunitiesByPublishDate called with opportunities:", opportunities);
    return opportunities?.sort((a, b) => {
      const dateA = new Date(a.postedDate);
      const dateB = new Date(b.postedDate);
      if (dateA - dateB) {
        console.log(dateA - dateB, "sortDate")
        return dateA - dateB;
      }
    });
  };


  const sortOpportunitiesByResponseDate = (opportunities) => {
    console.log("sortOpportunitiesByResponseDate called with opportunities:", opportunities);
    return opportunities?.sort((a, b) => {
      const dateA = new Date(a.responseDeadLine);
      const dateB = new Date(b.responseDeadLine);
      if (dateA - dateB) {
        return dateA - dateB;
      }
    });
  };


  const sortOpportunitiesByDepartment = (opportunities) => {
    console.log("sortOpportunitiesByNoticeId called with opportunities:", opportunities);
    return opportunities?.sort((a, b) => {
      const deptA = (a.fullParentPathName?.split(".")[0]);
      const deptB = (b.fullParentPathName?.split(".")[0]);
      // console.log(dateA,dateB,"dateBdateB")
      if (deptA && deptB) {

        return deptA.localeCompare(deptB)
      }
      // return dateA - dateB;
    });
  };


  const sortOpportunitiesBySubtier = (opportunities) => {
    console.log("sortOpportunitiesByNoticeId called with opportunities:", opportunities);
    return opportunities?.sort((a, b) => {
      const subtierA = (a.fullParentPathName?.split(".")[1]);
      const subtierB = (b.fullParentPathName?.split(".")[1]);
      // console.log(dateA,dateB,"dateBdateB")
      if (subtierA && subtierB) {

        return subtierA.localeCompare(subtierB)
      }
      // return dateA - dateB;
    });
  };


  const sortOpportunitiesByOffice = (opportunities) => {
    console.log("sortOpportunitiesByNoticeId called with opportunities:", opportunities);
    return opportunities?.sort((a, b) => {
      console.log(a.fullParentPathName, "fullParentPathName");
      let officeA
      let officeB
      if (a?.fullParentPathName && b?.fullParentPathName) {
        const splitA = a.fullParentPathName.split(".");
        const splitB = b.fullParentPathName.split(".");

        officeA = splitA[splitA.length - 1];
        officeB = splitB[splitB.length - 1];

        console.log(officeA, officeB, "dateBdateB");
      }



      if (officeA && officeB) {
        return officeA.localeCompare(officeB)
      }
      // return dateA - dateB;
    });
  };


  const sortOpportunitiesBynoticeType = (opportunities) => {
    console.log("sortOpportunitiesByNoticeId called with opportunities:", opportunities);
    return opportunities?.sort((a, b) => {
      const noticeTypeA = (a.type);
      const noticeTypeB = (b.type);
      // console.log(dateA,dateB,"dateBdateB")
      if (noticeTypeA && noticeTypeB) {

        return noticeTypeA.localeCompare(noticeTypeB)
      }
      // return dateA - dateB;
    });
  };




  const enableLoading = () => {
    setLoading(true)
  }


  const disableLoading = () => {
    setLoading(false)

  }

  const handleFilterSideClick = () => {
    setIsFilterSideVisible(!isFilterSideVisible); // Toggle visibility state
  };

  const handleFilterButtonClick = () => {
    setFilterBlockVisibility(!isFilterBlockVisible);
  };

  const handleFedOrgClick = () => {
    setFedOrgFilter(!isFedOrgFilter);
  };
  const handleNoticeTypeClick = () => {
    setNoticeTypeFilter(!isNoticeTypeFilter);
  };
  const handleNaicsClick = () => {
    setNaicsFilter(!isNaicsFilter);
  };
  const handlePronServiceClick = () => {
    setPronServiceFilter(!isPronServiceFilter);
  };
  const handleSetAsideClick = () => {
    setSetAsideFilter(!isSetAsideFilter);
  };
  const handleStateClick = () => {
    setStateFilter(!isStateFilter);
  };
  const handleCountryClick = () => {
    setCountryFilter(!isCountryFilter);
  };
  const handleStatusClick = () => {
    setStatusFilter(!isStatusFilter);
  };

  // Select all cards
  const handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;
    setSelectAllChecked(isChecked);
    setMyDocs((prevDocuments) => {
      return prevDocuments.map((doc) => ({
        ...doc,
        isChecked: isChecked,
      }));
    });

    // setDeletedDocs((prevDocuments) => {
    //     return prevDocuments.map((doc) => ({
    //         ...doc,
    //         isChecked: isChecked
    //     }));
    // });

    // setSharedDocs((prevDocuments) => {
    //     return prevDocuments.map((doc) => ({
    //         ...doc,
    //         isChecked: isChecked
    //     }));
    // });
  };

  // Download pdf and word doc
  const handleDownloadDropdownClick = (event) => {
    setDownloadAnchorElDropdown(event.currentTarget);
  };

  const handleDownloadDropdownClose = () => {
    setDownloadAnchorElDropdown(null);
  };

  const handleWordDownload = () => {
    const selectedDocuments = documents.filter((docs) => docs.isChecked);

    if (selectedDocuments.length === 0) {
      showToast('Warning.selectDocDownload')
      return;
    }

    const wordDocContent = selectedDocuments
      .map((doc) => {
        const parsedJson = doc.onlyDocumentContent
          ? JSON.parse(doc.onlyDocumentContent)
          : { blocks: [{ text: "" }] };
        let description = parsedJson.blocks[0].text;
        return `${doc.documentName}<br/>${description}<br/>---------------------------------------------------<br/><br/>`;
      })
      .join("");

    asBlob(wordDocContent).then((docx) => {
      saveAs(docx, `Documents.docx`);
    });
  };
  const handlePDFDownload = () => {
    const selectedDocuments = documents.filter((docs) => docs.isChecked);

    if (selectedDocuments.length === 0) {
      showToast('Warning.selectDocDownload')
      return;
    }

    const pdfContent = selectedDocuments
      .map((doc) => {
        const parsedJson = doc.onlyDocumentContent
          ? JSON.parse(doc.onlyDocumentContent)
          : { blocks: [{ text: "" }] };
        let description = parsedJson.blocks[0].text;

        // Customize the HTML structure as needed
        return `<div><h5>${doc.documentName}</h5><p>${description}</p>---------------------------------------------------------</div>`;
      })
      .join("");

    const element = document.createElement("div");
    element.innerHTML = pdfContent;

    html2pdf(element, {
      margin: 10,
      filename: "Documents.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    }).then((pdf) => {
      saveAs(pdf, "Documents.pdf");
    });
  };
  const handleDropdownSelectforDownload = (selectedOption) => {
    console.log(selectedOption, "selected option");
    switch (selectedOption) {
      case "MS-Word":
        handleWordDownload();
        break;
      case "PDF":
        console.log("PDF");
        handlePDFDownload();
        break;
    }
    handleDownloadDropdownClose();
  };

  // Sorting documents
  const handleSortClick = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
    const sortMyDocs = [...myDocs].sort((a, b) => {
      const titleA = a.documentName.toUpperCase();
      const titleB = b.documentName.toUpperCase();

      return newSortOrder === "asc"
        ? titleA.localeCompare(titleB)
        : titleB.localeCompare(titleA);
    });
    const sortDeletedDocs = [...deletedDocs].sort((a, b) => {
      const titleA = a.documentName.toUpperCase();
      const titleB = b.documentName.toUpperCase();

      return newSortOrder === "asc"
        ? titleA.localeCompare(titleB)
        : titleB.localeCompare(titleA);
    });
    const sortSharedDocs = [...sharedDocs].sort((a, b) => {
      const titleA = a.documentName.toUpperCase();
      const titleB = b.documentName.toUpperCase();

      return newSortOrder === "asc"
        ? titleA.localeCompare(titleB)
        : titleB.localeCompare(titleA);
    });

    setMyDocs(sortMyDocs);
    setDeletedDocs(sortDeletedDocs);
    setSharedDocs(sortSharedDocs);
  };

  // Search Documents
  const handleSearchChange = (e) => {
    const newSearchTerm = e.target.value.toLowerCase();

    const filteredMyDocs = initialMyDocs.filter((doc) =>
      doc.documentName.toLowerCase().includes(newSearchTerm)
    );
    const filteredDeletedDocs = initialDeletedDocs.filter((doc) =>
      doc.documentName.toLowerCase().includes(newSearchTerm)
    );
    const filteredSharedDocs = initialSharedDocs.filter((doc) =>
      doc.documentName.toLowerCase().includes(newSearchTerm)
    );

    setMyDocs(() => (newSearchTerm ? filteredMyDocs : initialMyDocs));
    setDeletedDocs(() =>
      newSearchTerm ? filteredDeletedDocs : initialDeletedDocs
    );
    setSharedDocs(() =>
      newSearchTerm ? filteredSharedDocs : initialSharedDocs
    );
  };

  // Individual Card Selection
  const handleMyDocsCardChange = (index) => {
    setMyDocs((prevDocuments) => {
      const newDocuments = [...prevDocuments];
      newDocuments[index].isChecked = !newDocuments[index].isChecked;
      return newDocuments;
    });
  };

  // const handleDeletedDocsCardChange = (index) => {
  //     setDeletedDocs((prevDocuments) => {
  //       const newDocuments = [...prevDocuments];
  //       newDocuments[index].isChecked = !newDocuments[index].isChecked;

  //       // Check if all individual checkboxes are checked
  //       const areAllChecked = newDocuments.every((doc) => doc.isChecked);
  //       return newDocuments;
  //     });
  //   };

  // const handleSharedDocsCardChange = (index) => {
  //     setSharedDocs((prevDocuments) => {
  //       const newDocuments = [...prevDocuments];
  //       newDocuments[index].isChecked = !newDocuments[index].isChecked;

  //       // Check if all individual checkboxes are checked
  //       const areAllChecked = newDocuments.every((doc) => doc.isChecked);
  //       return newDocuments;
  //     });
  //   };

  // API Calls for getting docs, deleted docs and shared docs
  const getDocs = async () => {
    setMyDocs(docList);
    setInitialMyDocs(docList);
    // try {
    //     const response = await documentService.getAllDocuments();
    //     if (response.status === 200) {
    //         setMyDocs(response.data);
    //         setInitialMyDocs(response.data);
    //     } else {
    //         console.error("Error fetching data:", response.message);
    //     }
    // } catch (error) {
    //     console.error("Error calling backend API:", error.message);
    // }
  };

  const getDeletedDocs = async () => {
    setLoading(true)
    try {
      const response = await documentService.getDeletedDocuments();
      if (response.status === 200) {
        setDeletedDocs(response.data);
        setInitialDeletedDocs(response.data);
      } else {
        console.error("Error fetching data:", response.message);
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }
    setLoading(false)

  };

  const getSharedDocs = async () => {
    setLoading(true)

    try {
      const response = await documentService.getSharedDocuments();
      if (response.status === 200) {
        setSharedDocs(response.data);
        setInitialSharedDocs(response.data);
      } else {
        console.error("Error fetching data:", response.message);
        setSharedDocs([]);
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }
    setLoading(false)

  };

  const getAllDocuments = () => {
    getDocs();
    getDeletedDocs();
    getSharedDocs();
  };

  useEffect(() => {
    getAllDocuments();
  }, []);

  useEffect(() => {
    setDocuments([...myDocs, ...deletedDocs, ...sharedDocs]);
  }, [myDocs, deletedDocs, sharedDocs]);

  const getFederalorganization = async () => {
    setLoading(true)

    try {
      const response = await federalorganizationsService.getfederalorgs();
      let federalorgResponse = response.allFederalOrgs;
      setfederalOrgData(federalorgResponse);
    } catch (error) {
      console.error("Error fetching federalorganizations data:", error.message);
      // setLoading(false);
    }
    setLoading(false)

  };

  const getNoticetypes = async () => {
    setLoading(true)

    try {
      const response = await noticetypesService.getnoticetypes();
      let noticetypesResponse = response.data;
      setNoticetypesData(noticetypesResponse);
    } catch (error) {
      console.error("Error fetching Noticetype data:", error.message);
      // setLoading(false);
    }
    setLoading(false)

  };

  const getNaics = async () => {
    setLoading(true)

    try {
      const response = await naicsService.getnaics();
      let naicsResponse = response.allnaics;
      setNaicsData(naicsResponse);
    } catch (error) {
      console.error("Error fetching Naic code data:", error.message);
      // setLoading(false);
    }
    setLoading(false)

  };

  const getProductnServicecode = async () => {
    setLoading(true)

    try {
      const response =
        await productnServiceCodeService.getproductnServiceCode();
      let productnServicecodeResponse = response.checkProduct;
      setProductnservicecodesData(productnServicecodeResponse);
    } catch (error) {
      console.error(
        "Error fetching product & service  code data:",
        error.message
      );
      setLoading(false);
    }
    setLoading(false)

  };

  const getSetasides = async () => {
    setLoading(true)
    try {
      const response = await setasidesService.getsetAsides();
      let setAsidesResponse = response.checksetAsides;
      setsetasidesData(setAsidesResponse);
    } catch (error) {
      console.error("Error fetching setAside data:", error.message);
      // setLoading(false);
    }
    setLoading(false)

  };

  const getStates = async () => {
    setLoading(true)

    try {
      const response = await locationstateService.getlocationStates();
      let stateResponse = response.data;
      setstatesData(stateResponse);
    } catch (error) {
      console.error("Error fetching States data:", error.message);
      // setLoading(false);
    }
    setLoading(false)

  };

  const getCountries = async () => {
    setLoading(true)

    try {
      const response = await locationcountryService.getcountry();
      let countryResponse = response.checkcountry;
      setcountriesData(countryResponse);
    } catch (error) {
      console.error("Error fetching States data:", error.message);
      // setLoading(false);
    }
    setLoading(false)

  };

  const indexOfLastOpportunity = currentPage * itemsPerPage;
  const indexOfFirstOpportunity = indexOfLastOpportunity - itemsPerPage;
  const currentOpportunities = opportunity?.slice(
    indexOfFirstOpportunity,
    indexOfLastOpportunity
  );

  const fetchData = async (options, status = 'none') => {
    try {
      setLoading(true);
      console.log(options, showPastOpportunities, "   fetchValue1");
      const data = {
        data: options || "",
        currentPage,
        itemsPerPage: limitValue,
      };
      console.log(data, "page data", status);
      const response = await opportunityService.searchOpportunity(data, showPastOpportunities, status);
      console.log(response, "opportunityResponse");
      setOpportunity(response?.data);
      setTotalPages(response?.totalPages);
      setTotalResults(response?.total);
      updatePageRange(currentPage, limitValue, response?.total);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  console.log(totalResults, "totalData");

  const handleRefresh = async () => {

    // setFilterOptions("");
    setFilterOptions([]);

  };

  useEffect(() => {
    if (filterOptions.length === 0) {
      fetchData(filterOptions);

    }
  }, [filterOptions]);


  // Function to update page range
  const updatePageRange = (currentPage, limitValue, totalResults) => {
    const from = (currentPage - 1) * limitValue + 1;
    const to = Math.min(currentPage * limitValue, totalResults);
    setFromPage(from);
    setToPage(to);
  };

  // Function to handle next page
  const handleNextPage = async () => {
    let data;
    const page = currentPage + 1;
    setCurrentPage(page);
    data = {
      currentPage: page,
      itemsPerPage: limitValue,
    };
    setValue(data);
    //  fetchData();
  };

  // Function to handle previous page
  const handlePrevPage = () => {
    let data;
    const page = currentPage - 1;
    setCurrentPage(page);
    data = {
      currentPage: page,
      itemsPerPage: limitValue,
    };
    setValue(data);
    //  fetchData();
  };


  useEffect(() => {
    fetchData(filterOptions)
  }, [value, limitValue, searchQuery])


  // Function to handle page limit change
  const handlePageLimitChange = async (e) => {
    const newValue = e.target.value;
    setLimitValue(newValue);
    setCurrentPage(1);
    // fetchData();
  };

  // Function to handle federal organizations change
  const handleFederalOrganizationsChange = async (selectedOptions) => {
    console.log("")
    let data;
    setSelectedFilterFederal(selectedOptions);
    const fedOrg = selectedOptions.map((k) => k.name);
    data = {
      type: "federalOrganization",
      fedOrg,
      currentPage: 1,
      itemsPerPage: limitValue,
    };
    // console.log(data, "fData");
    // setFilterOptions((prevOptions) => [...prevOptions, data]);
    // console.log("Detect ", filterOptions)
    // fetchData(filterOptions);
    const newFilterOptions = [...filterOptions, data];
    setFilterOptions(newFilterOptions);
    console.log("Detect ", JSON.stringify(newFilterOptions));

    fetchData(newFilterOptions);
  };

  // Function to handle notice type change
  const handleNoticeTypeChange = async (selectedOptions) => {
    setSelectedFilterNotice(selectedOptions);
    const fedOrg = selectedOptions.map((k) => k.value);
    const data = {
      type: "noticeType",
      fedOrg,
      currentPage: 1,
      itemsPerPage: limitValue,
    };
    //  setFilterOptions((prevOptions) => [...prevOptions, data]);
    //   fetchData(filterOptions);

    const newFilterOptions = [...filterOptions, data];
    setFilterOptions(newFilterOptions);
    fetchData(newFilterOptions);

  };
  console.log(filterOptions, "fetchValue");
  // Similar functions for other filter types...

  const handleNaicsTypeChange = async (selectedOptions) => {
    setSelectedFilterNaics(selectedOptions);
    const fedOrg = selectedOptions.map((k) => k.naicsCode);
    const data = {
      type: "naics",
      fedOrg,
      currentPage: currentPage,
      itemsPerPage: limitValue,
    };
    // setFilterOptions((prevOptions) => [...prevOptions, data]);
    // fetchData(filterOptions);
    const newFilterOptions = [...filterOptions, data];
    setFilterOptions(newFilterOptions);
    fetchData(newFilterOptions);
  };

  const handleProductnSericeTypeChange = async (selectedOptions) => {

    setSelectedFilterPNS(selectedOptions);
    const fedOrg = selectedOptions.map((k) => k.pscCode);
    const data = {
      type: "productServiceCodes",
      fedOrg,
      currentPage: currentPage,
      itemsPerPage: limitValue,
    };
    // setFilterOptions((prevOptions) => [...prevOptions, data]);
    // fetchData(filterOptions);

    const newFilterOptions = [...filterOptions, data];
    setFilterOptions(newFilterOptions);

    // Pass the newFilterOptions to fetchData
    fetchData(newFilterOptions);
  };

  const handleSetAsideTypeChange = async (selectedOptions) => {

    setSelectedFilterSetAside(selectedOptions);
    const fedOrg = selectedOptions.map((k) => k.setAsideCode);
    const data = {
      type: "setAsides",
      fedOrg,
      currentPage: currentPage,
      itemsPerPage: limitValue,
    };
    // setFilterOptions((prevOptions) => [...prevOptions, data]);
    // fetchData(filterOptions);
    const newFilterOptions = [...filterOptions, data];
    setFilterOptions(newFilterOptions);

    // Pass the newFilterOptions to fetchData
    fetchData(newFilterOptions);
  };

  const handleStateTypeChange = async (selectedOptions) => {

    setSelectedFilterState(selectedOptions);
    const fedOrg = selectedOptions.map((k) => k.stateCode);
    const data = {
      type: "states",
      fedOrg,
      currentPage: currentPage,
      itemsPerPage: limitValue,
    };
    // setFilterOptions((prevOptions) => [...prevOptions, data]);
    // fetchData(filterOptions);

    const newFilterOptions = [...filterOptions, data];
    setFilterOptions(newFilterOptions);

    // Pass the newFilterOptions to fetchData
    fetchData(newFilterOptions);
  };

  const handleCountryTypeChange = async (selectedOptions) => {

    setSelectedFilterCountry(selectedOptions);
    const fedOrg = selectedOptions.map((k) => k.countrycode);
    const data = {
      type: "countries",
      fedOrg,
      currentPage: currentPage,
      itemsPerPage: limitValue,
    };

    const filteredData = selectedOptions
      .map((option) =>
        statesData.filter(
          (state) => state.country.countryCode2 === option.countryCode2
        )
      )
      .flat();
    setCountryState(filteredData);
    //   setFilterOptions((prevOptions) => [...prevOptions, data]);
    //  fetchData(filterOptions);
    const newFilterOptions = [...filterOptions, data];
    setFilterOptions(newFilterOptions);

    // Pass the newFilterOptions to fetchData
    fetchData(newFilterOptions);
  };

  const handleStatusFilterChange = async (e) => {

    // Determine the current checked state of each checkbox
    const isActive = document.getElementById("activeCheckbox").checked;
    const isInactive = document.getElementById("inactiveCheckbox").checked;

    console.log(isActive, isInactive, "activestatus");

    let status = "";

    if (isActive && isInactive) {
      status = "both"; // Both checkboxes checked
    } else if (isActive) {
      status = "Yes"; // Only active checkbox checked
    } else if (isInactive) {
      status = "No"; // Only inactive checkbox checked
    } else {
      status = "none"; // None of the checkboxes checked
    }

    const data = {
      type: "status",
      fedOrg: status,
      currentPage: 1,
      itemsPerPage: limitValue,
    };
    //   setFilterOptions((prevOptions) => [...prevOptions, data]);
    //  fetchData(filterOptions);
    const newFilterOptions = [...filterOptions, data];
    setFilterOptions(newFilterOptions);

    // Pass the newFilterOptions to fetchData
    fetchData(newFilterOptions, status);
  };

  // useEffect(() => {
  //   fetchData(filterOptions);
  // }, [
  //   currentPage,
  //   limitValue,
  //   totalPages,
  //   totalResults,
  //   value,
  //   searchQuery,
  //   searchKeyList,
  //   filterOptions,
  // ]);

  useEffect(() => {
    // fetchuserdata();
    getFederalorganization();
    getNaics();
    getNoticetypes();
    getProductnServicecode();
    getSetasides();
    getStates();
    getCountries();
  }, []);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);


  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setSearchKeyList((prevSearchKeyList) => {
        const updatedList = [...prevSearchKeyList, searchQuery];
        performSearch(updatedList); // Pass updatedList to performSearch
        return updatedList; // Return updatedList to setSearchKeyList
      });
      setSearchQuery(""); // Clear the input field after adding to searchKeyList
    }
  };

  const removeFromSearchKey = (dataToRemove) => {
    setSearchKeyList((prevSearchKeyList) => {
      const updatedList = prevSearchKeyList.filter(
        (data) => data !== dataToRemove
      );
      // Call performSearch after updating the state
      performSearch(updatedList);
      return updatedList; // Return updatedList to setSearchKeyList
    });
  };

  const handleClearAllFilter = () => {

    setFedOrgFilter(false)
    setNoticeTypeFilter(false)
    setNaicsFilter(false)
    setPronServiceFilter(false)
    setSetAsideFilter(false)
    setStateFilter(false)
    setCountryFilter(false)
    setStateFilter(false)

    setSelectedFilterFederal([])
    setSelectedFilterNotice([])
    setSelectedFilterNaics([])
    setSelectedFilterPNS([])
    setSelectedFilterSetAside([])
    setSelectedFilterState([])
    setSelectedFilterCountry([])

    // document.getElementById("activeCheckbox").checked = false;
    // document.getElementById("inactiveCheckbox").checked = false;


    const activeCheckbox = document.getElementById('activeCheckbox');
    if (activeCheckbox) activeCheckbox.checked = false;

    const inactiveCheckbox = document.getElementById('inactiveCheckbox');
    if (inactiveCheckbox) inactiveCheckbox.checked = false;

    setFilterOptions([])



  }

  const colorInfo = {
    red: "Delayed",
    orange: "Moderately delayed",
    green: "On time",
    grey: "Response date not available",
    black: "Ended",
  };

  const performSearch = (updatedList) => {
    // Accept updatedList as a parameter

    let data;
    if (updatedList.length !== 0) {
      const fedOrg = updatedList;
      data = {
        type: "searchKeys",
        fedOrg,
        currentPage: currentPage,
        itemsPerPage: limitValue,
      };
    } else {
      const fedOrg = "";
      data = { data: fedOrg, currentPage: 1, itemsPerPage: 25 };
    }

    // setFilterOptions((prevOptions) => [...prevOptions, data]);
    // fetchData(filterOptions);


    const newFilterOptions = [...filterOptions, data];
    setFilterOptions(newFilterOptions);

    // Pass the newFilterOptions to fetchData
    fetchData(newFilterOptions);



    sessionStorage.setItem("searchKeyList", JSON.stringify(updatedList));
    setSearchQuery("");
  };

  useEffect(() => {
    const savedSearchKeyList = sessionStorage.getItem("searchKeyList");
    if (savedSearchKeyList) {
      setSearchKeyList(JSON.parse(savedSearchKeyList));
    }
  }, []);
  console.log(selectedFiltersNaics, "naics");

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update windowWidth on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const mobileView = windowWidth < 960

  const contentStyle = {
    flex: `0 0 ${mobileView ? '100%' : (isFilterSideVisible ? '66.6667%' : '100%')}`, // Full width on mobile view
    maxWidth: mobileView ? '100%' : (isFilterSideVisible ? '66.6667%' : '100%'),
    paddingTop: '8px', // Adjust this to match Grid spacing
    // border: "1px solid green",
  };

  const filterSideContent = (

    // <div>
    <div
      // className={`popup-container ${isFilterSideVisible ? 'popup-enter-active' : 'popup-exit-active'}`}
      style={{
        flex: `0 0 ${isFilterSideVisible ? '33.3333%' : '0'}`, // 4 columns on md breakpoint
        maxWidth: isFilterSideVisible ? (mobileView ? '100%' : '33.3333%') : '0',
        position: mobileView ? 'relative' : 'sticky',
        top: mobileView ? "0px" : 150,
        maxHeight: 'calc(100vh - 74px)',
        overflowY: 'auto',
        // display: isFilterSideVisible ? 'block' : 'none',
        display: 'block',
        '-ms-overflow-style': 'none', /* IE and Edge */
        scrollbarWidth: 'none', /* Firefox */
      }}
    >
      <Paper elevation={3} style={{ padding: '1rem', }}>
        {isFilterBlockVisible && (
          <>
            {mobileView && <CloseIcon onClick={() => handleFilterSideClick()} />}
            <div style={{ marginBottom: "8px", marginTop: "7px" }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                <div>
                  <p className="keySearch_title">
                    Keyword Search
                  </p>
                </div>
                <div style={{ cursor: 'pointer' }} onClick={() => handleClearAllFilter()}>
                  <p style={{ marginTop: '3px' }}>
                    Clear All
                  </p>
                </div>
              </div>
              <input className="keyword_search"
                placeholder="e.g. W91QVN-17-R-008"
                value={searchQuery}
                onChange={handleSearchInputChange}
                onKeyDown={handleKeyPress} // Add this event handler
              />
            </div>
            {searchKeyList && (
              <>
                {searchKeyList.map((data, i) => (
                  <div className="searchKeyList" key={i}>
                    <div>{data}</div>
                    <p>
                      <CloseIcon
                        onClick={() => removeFromSearchKey(data)}
                      />
                      {/* Pass a function reference */}
                    </p>
                  </div>
                ))}
              </>
            )}

            <div
              className="filterBlock "
              onClick={handleFedOrgClick}

            >
              <p
                className="filterBlockp1"
              // style={{
              //   width: "80%",
              //   marginTop: "15px",
              //   fontSize: "15px",
              // }}
              >
                Federal Organizations
              </p>
              <p
                className="filterBlockp2"
              // style={{
              //   width: "8%",
              // }}
              >
                {!isFedOrgFilter ? (
                  <AddIcon
                    className="addIcon"
                  />
                ) : (
                  <RemoveIcon
                    className="addIcon"
                  />
                )}
              </p>
            </div>
            {isFedOrgFilter && (
              <>
                <div
                  className="ml-4 mt-2 mb-2"
                  style={{
                    backgroundColor: "#E6F5FB",
                    padding: "10px",
                  }}
                >
                  <MultiSelectDropdownWithSearch
                    optionList={federalOrgData}
                    onChange={handleFederalOrganizationsChange}
                    labelKey="name"
                    label={
                      <span
                        style={{ color: "3B1C32", fontWeight: "bold" }}
                      >
                        Federal Organization
                      </span>
                    }
                    fullWidth

                  />
                </div>
                {selectedFiltersFederal && (
                  <>
                    {selectedFiltersFederal?.map((filteredData, i) => (

                      <div className="ml-4 searchKeyList" key={i}>

                        <p>{filteredData?.name}</p>

                        <p>
                          {/* <CloseIcon
                                    onClick={() => {
                                      handleFederalOrganizationsChange(
                                        // filterOptions.filter(item => item.type !== filteredType)
                                      );
                                    }}
                                    /> */}
                        </p>
                      </div>
                    ))}
                  </>
                )}
              </>
            )}

            <div
              className="filterBlock"
              onClick={handleNoticeTypeClick}
            // style={{
            //   width: "100%",
            //   height: "35px",
            //   backgroundColor: "#E6F5FB",
            //   display: "flex",
            //   justifyContent: "space-between", // Align items at the start and end of the container
            //   alignItems: "center",
            // }}
            >
              <p
                className="filterBlockp1"
              // style={{
              //   width: "80%",
              //   marginTop: "15px",
              //   fontSize: "15px",
              // }}
              >
                Notice Type
              </p>
              <p
                className="filterBlockp2"
              // style={{
              //   width: "8%",
              // }}
              >
                {!isNoticeTypeFilter ? (
                  <AddIcon
                    className="addIcon"
                  />
                ) : (
                  <RemoveIcon
                    className="addIcon"
                  />
                )}
              </p>
            </div>
            {isNoticeTypeFilter && (
              <>
                <div
                  className="ml-4 mt-2 mb-2"
                  style={{
                    backgroundColor: "#E6F5FB",
                    padding: "10px",
                  }}
                >
                  <MultiSelectDropdownWithSearch
                    optionList={noticetypesData}
                    onChange={handleNoticeTypeChange}
                    labelKey="value"
                    label={
                      <span
                        style={{ color: "3B1C32", fontWeight: "bold" }}
                      >
                        Notice Type
                      </span>
                    }
                    fullWidth
                  />
                </div>
                {selectedFiltersNotice && (
                  <>
                    {selectedFiltersNotice?.map((data, i) => (
                      <div className="ml-4 searchKeyList" key={i}>
                        <p>{data?.value}</p>
                        <p>
                          {/* <CloseIcon
                                onClick={() => handleNoticeTypeChange(
                                  selectedFiltersNotice.filter(
                                    (item, index) => index !== i
                                  )
                                )}
                              /> */}
                          {/* Pass a function reference */}
                        </p>
                      </div>
                    ))}
                  </>
                )}
              </>
            )}

            <div
              className="filterBlock"
              onClick={handleNaicsClick}
            >
              <p
                className="filterBlockp1"
              >
                NAICS
              </p>
              <p
                className="filterBlockp2"
              >
                {!isNaicsFilter ? (
                  <AddIcon
                    className="addIcon"
                  />
                ) : (
                  <RemoveIcon
                    className="addIcon"
                  />
                )}
              </p>
            </div>
            {isNaicsFilter && (
              <>
                <div
                  className="ml-4 mt-2 mb-2"
                  style={{
                    backgroundColor: "#E6F5FB",
                    padding: "10px",
                  }}
                >
                  <MultiSelectDropdownWithSearch
                    optionList={naicsData}
                    onChange={handleNaicsTypeChange}
                    labelKey="newconcatenate"
                    label={
                      <span
                        style={{ color: "3B1C32", fontWeight: "bold" }}
                      >
                        NAICS
                      </span>
                    }
                    fullWidth
                  />
                </div>
                {selectedFiltersNaics && (
                  <>
                    {selectedFiltersNaics.map((data, i) => (
                      <div className="ml-4 searchKeyList" key={i}>
                        <p>{data?.naicsTitle}</p>
                        <p>
                          {/* <CloseIcon
                               onClick={() => handleNaicsTypeChange(
                                 selectedFiltersNaics.filter(
                                   (item, index) => index !== i
                                 )
                               )}
                             /> */}
                          {/* Pass a function reference */}
                        </p>
                      </div>
                    ))}
                  </>
                )}
              </>
            )}

            <div
              className="filterBlock"
              onClick={handlePronServiceClick}
            >
              <p
                className="filterBlockp1"
              >
                Product or Service Information
              </p>
              <p
                className="filterBlockp2"
              >
                {!isPronServiceFilter ? (
                  <AddIcon
                    className="addIcon"
                  />
                ) : (
                  <RemoveIcon
                    className="addIcon"
                  />
                )}
              </p>
            </div>
            {isPronServiceFilter && (
              <>
                <div
                  className="ml-4 mt-2 mb-2"
                  style={{
                    backgroundColor: "#E6F5FB",
                    padding: "10px",
                  }}
                >
                  <MultiSelectDropdownWithSearch
                    optionList={productnservicecodesData}
                    onChange={handleProductnSericeTypeChange}
                    labelKey="newconcatenate"
                    label={
                      <span
                        style={{ color: "#3B1C32", fontWeight: "bold" }}
                      >
                        Product & Service Code
                      </span>
                    }
                    fullWidth
                  />
                </div>
                {selectedFiltersPNS && (
                  <>
                    {selectedFiltersPNS.map((data, i) => (
                      <div className="ml-4 searchKeyList" key={i}>
                        <p>{data?.pscName}</p>
                        <p>
                          {/* <CloseIcon
                                onClick={() => handleProductnSericeTypeChange(
                                  selectedFiltersPNS.filter(
                                    (item, index) => index !== i
                                  )
                                )}
                              /> */}
                          {/* Pass a function reference */}
                        </p>
                      </div>
                    ))}
                  </>
                )}
              </>
            )}

            <div
              className="filterBlock"
              onClick={handleSetAsideClick}
            >
              <p
                className="filterBlockp1"
              >
                Set Aside
              </p>
              <p
                className="filterBlockp2"
              >
                {!isSetAsideFilter ? (
                  <AddIcon
                    className="addIcon"
                  />
                ) : (
                  <RemoveIcon
                    className="addIcon"
                  />
                )}
              </p>
            </div>
            {isSetAsideFilter && (
              <>
                <div
                  className="ml-4 mt-2 mb-2"
                  style={{
                    backgroundColor: "#E6F5FB",
                    padding: "10px",
                  }}
                >
                  <MultiSelectDropdownWithSearch
                    optionList={setasidesData}
                    onChange={handleSetAsideTypeChange}
                    labelKey="setAsideName"
                    label={
                      <span
                        style={{ color: "3B1C32", fontWeight: "bold" }}
                      >
                        Set Aside
                      </span>
                    }
                    fullWidth
                  />
                </div>
                {selectedFiltersSetAside && (
                  <>
                    {selectedFiltersSetAside.map((data, i) => (
                      <div className="ml-4 searchKeyList" key={i}>
                        <p>{data?.setAsideName}</p>
                        <p>
                          {/* <CloseIcon
                                onClick={() => handleSetAsideTypeChange(
                                  selectedFiltersSetAside.filter(
                                    (item, index) => index !== i
                                  )
                                )}
                              /> */}
                          {/* Pass a function reference */}
                        </p>
                      </div>
                    ))}
                  </>
                )}
              </>
            )}

            <div
              className="filterBlock"
              onClick={handleCountryClick}
            >
              <p
                className="filterBlockp1"
              >
                Country
              </p>
              <p
                className="filterBlockp2"
              >
                {!isCountryFilter ? (
                  <AddIcon
                    className="addIcon"
                  />
                ) : (
                  <RemoveIcon
                    className="addIcon"
                  />
                )}
              </p>
            </div>
            {isCountryFilter && (
              <>
                <div
                  className="ml-4 mt-2 mb-2"
                  style={{
                    backgroundColor: "#E6F5FB",
                    padding: "10px",
                  }}
                >
                  <MultiSelectDropdownWithSearch
                    optionList={countriesData}
                    onChange={handleCountryTypeChange}
                    labelKey="country"
                    label={
                      <span
                        style={{ color: "3B1C32", fontWeight: "bold" }}
                      >
                        Country
                      </span>
                    }
                    fullWidth
                  />
                </div>
                {selectedFiltersCountry && (
                  <>
                    {selectedFiltersCountry.map((data, i) => (
                      <div className="ml-4 searchKeyList" key={i}>
                        <p>{data?.country}</p>
                        <p>
                          {/* <CloseIcon
                                onClick={() => handleCountryTypeChange(
                                  selectedFiltersCountry.filter(
                                    (item, index) => index !== i
                                  )
                                )}
                              /> */}
                          {/* Pass a function reference */}
                        </p>
                      </div>
                    ))}
                  </>
                )}
              </>
            )}


            <div
              className="filterBlock"
              onClick={handleStateClick}
            >
              <p
                className="filterBlockp1"
              >
                State
              </p>
              <p
                className="filterBlockp2"
              >
                {!isStateFilter ? (
                  <AddIcon
                    className="addIcon"
                  />
                ) : (
                  <RemoveIcon
                    className="addIcon"
                  />
                )}
              </p>
            </div>
            {isStateFilter && (
              <>
                <div
                  className="ml-4 mt-2 mb-2"
                  style={{
                    backgroundColor: "#E6F5FB",
                    padding: "10px",
                  }}
                >
                  <MultiSelectDropdownWithSearch
                    optionList={countryState}
                    onChange={handleStateTypeChange}
                    labelKey="state"
                    label={
                      <span
                        style={{ color: "3B1C32", fontWeight: "bold" }}
                      >
                        State
                      </span>
                    }
                    fullWidth
                  />
                </div>
                {selectedFiltersState && (
                  <>
                    {selectedFiltersState.map((data, i) => (
                      <div className="ml-4 searchKeyList" key={i}>
                        <p>{data?.state}</p>
                        <p>
                          {/* <CloseIcon
                                onClick={() => handleStateTypeChange(
                                  selectedFiltersState.filter(
                                    (item, index) => index !== i
                                  )
                                )}
                              /> */}
                          {/* Pass a function reference */}
                        </p>
                      </div>
                    ))}
                  </>
                )}
              </>
            )}


            <div
              className="filterBlock"
              onClick={handleStatusClick}
            >
              <p
                className="filterBlockp1"
              >
                Status
              </p>
              <p
                className="filterBlockp2"
              >
                {!isStatusFilter ? (
                  <AddIcon
                    className="addIcon"
                  />
                ) : (
                  <RemoveIcon
                    className="addIcon"
                  />
                )}
              </p>
            </div>
            {isStatusFilter && (
              <>
                <div
                  style={{
                    backgroundColor: "#E6F5FB",
                    padding: "10px",
                  }}
                >
                  <input
                    type="checkbox"
                    id="activeCheckbox"
                    name="active"
                    value="active"
                    onChange={handleStatusFilterChange}
                  />
                  <label htmlFor="activeCheckbox">Active</label>
                  <br />
                  <input
                    type="checkbox"
                    id="inactiveCheckbox"
                    name="inactive"
                    value="inactive"
                    onChange={handleStatusFilterChange}
                  />
                  <label htmlFor="inactiveCheckbox">Inactive</label>
                </div>
              </>
            )}
          </>
        )}
        {/* <SearchOpportunityFilter
          updatedFilter={handleFilters}
        /> */}
      </Paper>

    </div>

  )

  return (
    <div style={{ marginLeft: mobileView < 960 ? "10px" : "0px", }}>
      <Loader loading={loading} />
      {/* <Loader loading={loading} /> */}
      <div style={{
        width: "100%",
        backgroundColor: "white",
        marginTop: "-15px",
        // borderBottom: "1px solid gray",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: windowWidth > 768 ? "row" : "column",
        // border: "1px solid blue",
        position: "sticky",
        top: "60px",
        zIndex: 1000,
        paddingTop: "15px",
        paddingBottom: "15px",
        borderBottom: "1px solid black"
      }}>
        <div style={{
          width: windowWidth > 768 ? "45%" : "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // border: "1px solid gray",
          marginBottom: windowWidth > 768 ? "0px" : "15px",
        }}>
          <div style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: windowWidth < 768 ? "30%" : "auto",
          }} >
            <IconButton
              style={{ outline: "none" }}
              onClick={handleFilterSideClick}
            >
              <FilterAltIcon style={{ color: "green" }} />
            </IconButton>
            <IconButton style={{ outline: "none", display: windowWidth > 768 ? "block" : "none" }}>
              {listView ? (
                <div title="Grid View">
                  <WindowIcon
                    style={{ color: "#87CEEB" }}
                    onClick={() => setListView(false)}
                  />
                </div>
              ) : (
                <div title="List View">
                  <TableRowsIcon
                    style={{ color: "#87CEEB" }}
                    onClick={() => setListView(true)}
                  />
                </div>
              )}
            </IconButton>
            <IconButton
              style={{ outline: "none" }}
              title="Refresh"
              onClick={handleRefresh}
            >
              <RefreshIcon />
            </IconButton>
            <IconButton style={{ border: "none", outline: "none" }}>
              <div title="Sort">

                {!dropdownVisible && ( // Conditionally render the icon
                  <SortByAlphaIcon onClick={handleIconClick} style={{ cursor: 'pointer', border: "none", outline: "none" }} />
                )}
                {/* <SortByAlphaIcon onClick={handleIconClick} style={{ cursor: 'pointer' }} /> */}
                {dropdownVisible && (
                  <div style={{ width: "150px", height: "25px", fontSize: "12px", outline: "none" }}>
                    <select style={{ width: "100%", height: "100%", outline: "none", border: "none" }} id="sortSelect" ref={dropdownRef} value={sortBy} onChange={handleSortChange}>
                      <option value="">Select sorting criteria</option>
                      <option value="title">Title</option>
                      <option value="publishDate">Publish Date</option>
                      <option value="responseDate">Response Date</option>
                      <option value="department">Department </option>
                      <option value="subtier">Subtier </option>
                      <option value="office">Office </option>
                      <option value="noticeType">Notice Type </option>
                    </select>
                  </div>
                )}

              </div>
            </IconButton>

            <Menu
              anchorEl={downloadAnchorElDropdown}
              open={Boolean(downloadAnchorElDropdown)}
              onClose={handleDownloadDropdownClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem
                onClick={() => handleDropdownSelectforDownload("MS-Word")}
              >
                <ListItemText primary="MS-Word" />
              </MenuItem>

              <MenuItem
                onClick={() => handleDropdownSelectforDownload("PDF")}
              >
                <ListItemText primary="PDF" />
              </MenuItem>
            </Menu>
          </div>
          <div
            className={windowWidth < 375 ? "row" : undefined}
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              width: windowWidth < 768 ? "60%" : "auto",
              // border: "1px solid green"
            }}>
            <span
              className={windowWidth < 375 ? "col-5" : undefined}
              style={{
                color: showPastOpportunities ? 'grey' : 'blue',
                fontWeight: showPastOpportunities ? '100' : '600',
                fontSize: windowWidth < 425 ? "0.8rem" : undefined,
              }}>Future Opportunities</span>
            <FormControlLabel
              className={windowWidth < 375 ? "col-1" : undefined}
              sx={{
                display: 'block',

              }}
              control={
                <Switch
                  checked={showPastOpportunities}
                  onChange={handlePastDocumentClick}
                  name="Opportunities"
                  color="default"
                  sx={{
                    transform: 'scale(0.8)', // Reduce the size of the switch
                    margin: '-5px', // Adjust margin if needed
                  }}
                />
              }
            />
            <span
              className={windowWidth < 375 ? "col-6" : undefined}
              style={{
                color: showPastOpportunities ? 'blue' : 'grey',
                fontWeight: showPastOpportunities ? '600' : '100',
                fontSize: windowWidth < 425 ? "0.8rem" : undefined,
              }}>Past Opportunities</span>
          </div>


        </div>
        <div style={{
          width: windowWidth > 768 ? "50%" : "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingRight: windowWidth > 768 ? "30px" : "10px",
          // border: "1px solid orange",
          paddingLeft: windowWidth > 768 ? "60px" : "0px",
        }}>
          {/* <div style={{ justifySelf: "flex-start", width: windowWidth > 768 ? "100%" : "30%" }}>
            <p>Items Per Page</p>
            <div style={{ marginTop: "-10px", width: "100px" }}>
              <SelectDropdownMui
                optionsList={limitList}
                label={limitValue}
                onChange={handlePageLimitChange}
                value={limitValue}
              />
            </div>
          </div> */}
          <div style={{
            // border: "2px solid blue",
            display: "flex",
            flexDirection: windowWidth < 1172 ? "column" : "row",
            justifyContent: "space-between",
            marginLeft: "10px",
            // width: "70%"
            width: windowWidth > 768 ? "100%" : "60%"
          }}>
            {opportunity?.length > 0 && (
              <div style={{ textAlign: 'right' }}>
                <p style={{ marginTop: '10px', color: "blue" }}>
                  {/* Showing {fromPage}-{toPage} of {totalResults} results */}
                  Showing {totalResults} Results
                </p>

              </div>
            )}

            <div style={{ display: 'flex', gap: '7px', marginLeft: '20px', justifyContent: 'flex-end' }}>
              {Object.entries(colorInfo).map(([color, text]) => (
                <span key={color}>
                  <CircleIcon data-tip data-for={color} style={{ color: color }} />
                  <ReactTooltip id={color} effect="solid" place="bottom">
                    <p>{text}</p>
                  </ReactTooltip>
                </span>
              ))}

            </div>
          </div>
        </div>

      </div>
      <div style={{ width: "100%", height: "20px", backgroundColor: "white" }}></div>
      <div style={{ display: 'flex', justifyContent: "space-between", gap: '16px', flexDirection: mobileView ? 'column' : 'row' }}>
        {isFilterSideVisible && (
          <>
            {!mobileView ? (
              filterSideContent
            ) : (
              <div className={`sliding-div ${isFilterSideVisible ? 'open' : ''}`} style={{ width: windowWidth < 600 ? "80%" : "60%" }}>
                {filterSideContent}
              </div>
            )}
          </>

        )}
        {/* right section */}

        <div style={contentStyle}>
          {opportunity?.length > 0 && (
            <Paper elevation={3} style={{ padding: '1rem', marginBottom: '1rem', height: '66vh', overflowY: 'auto', overflowX: 'hidden' }}>
              <div className="row">
                <div className="col-md-12 mt-1 mb-3">
                  <hr />
                  {!isFilterSideVisible && selectedFiltersFederal && (
                    <>
                      {selectedFiltersFederal?.map((data, i) => (
                        <span
                          key={i}
                          style={{
                            backgroundColor: "lightgreen",
                            width: "auto",
                            padding: "10px",
                            fontSize: "10px",
                            borderRadius: "5px",
                            marginRight: "5px",
                          }}
                        >
                          {data?.name}
                          <CloseIcon
                            style={{ fontSize: "10px", marginLeft: "3px" }}
                            onClick={() =>
                              handleFederalOrganizationsChange(
                                selectedFiltersFederal.filter(
                                  (item, index) => index !== i
                                )
                              )
                            }
                          />
                        </span>
                      ))}
                    </>
                  )}
                  {!isFilterSideVisible && selectedFiltersNotice && (
                    <>
                      {selectedFiltersNotice?.map((data, i) => (
                        <span
                          key={i}
                          style={{
                            backgroundColor: "lightgreen",
                            width: "auto",
                            padding: "10px",
                            fontSize: "10px",
                            borderRadius: "5px",
                            marginRight: "5px",
                          }}
                        >
                          {data?.value}
                          <CloseIcon
                            style={{ fontSize: "10px", marginLeft: "3px" }}
                            onClick={() =>
                              handleNoticeTypeChange(
                                selectedFiltersNotice?.filter(
                                  (item, index) => index !== i
                                )
                              )
                            }
                          />
                        </span>
                      ))}
                    </>
                  )}
                  {!isFilterSideVisible && selectedFiltersNaics && (
                    <>
                      {selectedFiltersNaics?.map((data, i) => (
                        <span
                          key={i}
                          style={{
                            backgroundColor: "lightgreen",
                            width: "auto",
                            padding: "10px",
                            fontSize: "10px",
                            borderRadius: "5px",
                            marginRight: "5px",
                          }}
                        >
                          {data?.naicsTitle}
                          <CloseIcon
                            style={{ fontSize: "10px", marginLeft: "3px" }}
                            onClick={() =>
                              handleNaicsTypeChange(
                                selectedFiltersNaics?.filter(
                                  (item, index) => index !== i
                                )
                              )
                            }
                          />
                        </span>
                      ))}
                    </>
                  )}
                  {!isFilterSideVisible && selectedFiltersPNS && (
                    <>
                      {selectedFiltersPNS?.map((data, i) => (
                        <span
                          key={i}
                          style={{
                            backgroundColor: "lightgreen",
                            width: "auto",
                            padding: "10px",
                            fontSize: "10px",
                            borderRadius: "5px",
                            marginRight: "5px",
                          }}
                        >
                          {data?.pscName}
                          <CloseIcon
                            style={{ fontSize: "10px", marginLeft: "3px" }}
                            onClick={() =>
                              handleProductnSericeTypeChange(
                                selectedFiltersPNS?.filter(
                                  (item, index) => index !== i
                                )
                              )
                            }
                          />
                        </span>
                      ))}
                    </>
                  )}
                  {!isFilterSideVisible && selectedFiltersSetAside && (
                    <>
                      {selectedFiltersSetAside?.map((data, i) => (
                        <span
                          key={i}
                          style={{
                            backgroundColor: "lightgreen",
                            width: "auto",
                            padding: "10px",
                            fontSize: "10px",
                            borderRadius: "5px",
                            marginRight: "5px",
                          }}
                        >
                          {data?.setAsideName}
                          <CloseIcon
                            style={{ fontSize: "10px", marginLeft: "3px" }}
                            onClick={() =>
                              handleSetAsideTypeChange(
                                selectedFiltersSetAside?.filter(
                                  (item, index) => index !== i
                                )
                              )
                            }
                          />
                        </span>
                      ))}
                    </>
                  )}
                  {!isFilterSideVisible && selectedFiltersState && (
                    <>
                      {selectedFiltersState?.map((data, i) => (
                        <span
                          key={i}
                          style={{
                            backgroundColor: "lightgreen",
                            width: "auto",
                            padding: "10px",
                            fontSize: "10px",
                            borderRadius: "5px",
                            marginRight: "5px",
                          }}
                        >
                          {data?.state}
                          <CloseIcon
                            style={{ fontSize: "10px", marginLeft: "3px" }}
                            onClick={() =>
                              handleStateTypeChange(
                                selectedFiltersState?.filter(
                                  (item, index) => index !== i
                                )
                              )
                            }
                          />
                        </span>
                      ))}
                    </>
                  )}
                  {!isFilterSideVisible && selectedFiltersCountry && (
                    <>
                      {selectedFiltersCountry?.map((data, i) => (
                        <span
                          key={i}
                          style={{
                            backgroundColor: "lightgreen",
                            width: "auto",
                            padding: "10px",
                            fontSize: "10px",
                            borderRadius: "5px",
                            marginRight: "5px",
                          }}
                        >
                          {data?.country}
                          <CloseIcon
                            style={{ fontSize: "10px", marginLeft: "3px" }}
                            onClick={() =>
                              handleCountryTypeChange(
                                selectedFiltersCountry?.filter(
                                  (item, index) => index !== i
                                )
                              )
                            }
                          />
                        </span>
                      ))}
                    </>
                  )}

                  {!isFilterSideVisible && searchKeyList && (
                    <>
                      {searchKeyList?.map((data, i) => (
                        <span
                          key={i}
                          style={{
                            backgroundColor: "lightgreen",
                            width: "auto",
                            padding: "10px",
                            fontSize: "10px",
                            borderRadius: "5px",
                            marginRight: "5px",
                          }}
                        >
                          {data}
                          <CloseIcon
                            style={{ fontSize: "10px", marginLeft: "3px" }}
                            onClick={() => removeFromSearchKey(data)}
                          />
                        </span>
                      ))}
                    </>
                  )}
                </div>

                {opportunity?.map((document, index) => {
                  const parsedJson = document?.onlyDocumentContent
                    ? JSON.parse(document.onlyDocumentContent)
                    : { blocks: [{ text: "" }] };
                  let description = parsedJson.blocks[0].text;
                  return (
                    <>

                      <div
                        style={{
                          width: listView ? "98%" : "48.5%",
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: "50px",
                          // border: "2px solid gray",
                          padding: "10px",
                          marginLeft: windowWidth > 375 ? "1%" : "-20px",
                          marginTop: "-60px"
                        }}
                        key={index}
                      >
                        {listView ? (
                          <DocumentListCard
                            title={document?.title}
                            description={document?.descriptionData}
                            noticeId={document?.solicitationNumber}
                            department={document?.fullParentPathName}
                            subtier={document?.type}
                            office={document?.officeAddress}
                            date={document?.postedDate}
                            date1={document?.responseDeadLine}
                            status={document?.active}
                            viewType="searchScreen"
                            id={document?._id}
                            count={document?.count}
                            // cardChecked={document.isChecked || false}
                            documentId={document._id}
                            onCardCheckboxChange={() =>
                              handleMyDocsCardChange(index)
                            }
                            getAllDocuments={getAllDocuments}
                            type={"MyDocuments"}
                            enableLoading={enableLoading}
                            disableLoading={disableLoading}
                            showPastOpportunities={showPastOpportunities}

                          />

                        ) : (
                          <DocumentGridCard
                            title={document?.title}
                            description={document?.descriptionData}
                            noticeId={document?.solicitationNumber}
                            department={document?.fullParentPathName}
                            subtier={document?.type}
                            office={document?.officeAddress}
                            date={document?.postedDate}
                            date1={document?.responseDeadLine}
                            status={document?.active}
                            viewType="searchScreen"
                            id={document?._id}
                            count={document?.count}
                            // cardChecked={document.isChecked || false}
                            documentId={document._id}
                            onCardCheckboxChange={() =>
                              handleMyDocsCardChange(index)
                            }
                            getAllDocuments={getAllDocuments}
                            type={"MyDocuments"}
                            enableLoading={enableLoading}
                            disableLoading={disableLoading}
                            showPastOpportunities={showPastOpportunities}
                          />
                        )}

                      </div>
                    </>
                  );
                })}
                {/* <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "15px",
                    marginBottom: "15px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      padding: "0 10px",
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifySelf: "flex-end",
                      }}
                    >
                      <button
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                      >
                        <ChevronLeftIcon />
                      </button>
                      <span className="mx-2">
                        Page {currentPage} of {totalPages}
                      </span>
                      <button
                        onClick={handleNextPage}
                        disabled={
                          currentPage === totalPages || totalPages === 0
                        }
                      >
                        <ChevronRightIcon />
                      </button>
                    </div>
                    <div style={{ width: "40%", justifySelf: "flex-start" }}>
                      <p>Items Per Page</p>
                      <div style={{ marginTop: "-10px", width: "100px" }}>
                        <SelectDropdownMui
                          optionsList={limitList}
                          label={limitValue}
                          onChange={handlePageLimitChange}
                          value={limitValue}
                        />
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </Paper>
          )
          }
          {opportunity?.length > 0 && <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              marginTop: "15px",
              marginBottom: "15px",
              backgroundColor: "white",
              padding: "10px"
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                padding: "0 10px",
              }}
            >
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifySelf: "flex-end",
                }}
              >
                <button
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                >
                  <ChevronLeftIcon />
                </button>
                <span className="mx-2">
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  onClick={handleNextPage}
                  disabled={
                    currentPage === totalPages || totalPages === 0
                  }
                >
                  <ChevronRightIcon />
                </button>
              </div>
              <div style={{ width: "40%", justifySelf: "flex-start" }}>
                <p>Items Per Page</p>
                <div style={{ marginTop: "-10px", width: "100px" }}>
                  <SelectDropdownMui
                    optionsList={limitList}
                    label={limitValue}
                    onChange={handlePageLimitChange}
                    value={limitValue}
                  />
                </div>
              </div>
            </div>
          </div>}
          {(
            opportunity?.length === 0 &&
            (
              isFedOrgFilter ||
              isNoticeTypeFilter ||
              isNaicsFilter ||
              isPronServiceFilter ||
              isSetAsideFilter ||
              isStateFilter ||
              isCountryFilter ||
              isStatusFilter
            )
          ) && (
              <h3>No results match your search. Please revise your criteria and try again.</h3>
            )}
        </div>


      </div>
    </div>
  );
};

export default SearchOpportunity;
