import React, { useState, useEffect, useRef } from "react";
import { Modal, Checkbox, Typography, Box, Button, IconButton } from "@material-ui/core";
import { toast } from "react-toastify";
import Select from "react-select";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import '../../../css/opportunity_Screen_CSS/AttachmentModal.css'
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckboxesTags from "../../CheckBoxTags";
// export const AttachmentModal = (props) => {
//   const {
//     open,
//     close,
//     documentData,
//     resourceLinks,
//     handleAttachment,
//     sectorData,
//     defaultSector,
//     selectedSectors,
//     handleSectorFieldChange,
//     customStyles1,
//     selectedFields,
//     AiData,
//     handleSelectFieldChange,
//     sectionCreation,
//     handleCallFileReader
//   } = props;

//   const style = {
//     position: "absolute",
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     backgroundColor: "white",
//     padding: "20px",
//     borderRadius: "10px",
//     outline: "none",
//     boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
//     width: "70%",
//     height: "fit-content",
//     overflowY: "auto",
//   };
//   useEffect(() => {
//     console.log("AttachmentModal", props);
//   }, [props]);

//   const isPDFAttachment = (attachmentName) => {
//     return (
//       attachmentName?.toLowerCase().endsWith(".pdf") ||
//       attachmentName?.toLowerCase().endsWith(".docx")
//     );
//   };

//   return (
//     <Modal open={open} onClose={close}>
//       <Box sx={style}>
//         <Box style={{ display: "flex", justifyContent: "space-between" }}>
//           <Typography variant="h5" component="div" style={{ color: "#1976D2" }}>
//             Documents List
//           </Typography>

//           <div style={{ width: "70%" }}>
//             <div
//               style={{
//                 width: "100%",
//                 display: "flex",
//                 justifyContent: "space-evenly",
//               }}
//             >
//               <input type="text" value={defaultSector} disabled/>
//               {/* <Select
//                 value={defaultSector} // Set the value here
//                 // placeholder="Select Sector"
//                 isDisabled={true} // Disable the dropdown
//               /> */}
//               <Select
//                 isMulti
//                 options={AiData}
//                 value={selectedFields}
//                 onChange={handleSelectFieldChange}
//                 styles={customStyles1}
//                 placeholder="Ai Sections"
//               />
//             </div>
//             {/* <div style={{ marginTop: '7px', display: 'flex', justifyContent: 'flex-end' }}>
//                 <p style={{ cursor: 'pointer' }} onClick={handleClearAllManualSection}>Clear All</p>
//               </div> */}
//             <Button
//               variant="contained"
//               color="primary"
//               style={{ marginRight: "15px", padding: "0.3rem" }}
//               onClick={sectionCreation}
//             >
//               Create Sections
//             </Button>
//           </div>
//         </Box>

//         <Box>
//           {documentData &&
//             documentData.map((attachment, index) => {
//               const attachmentName = attachment?.linkname;
//               if (isPDFAttachment(attachmentName)) {
//                 const isSelected = resourceLinks?.includes(attachment.url);
//                 return (
//                   <Box
//                     className="row"
//                     key={index}
//                     style={{
//                       display: "flex",
//                       alignItems: "center",
//                       marginTop: "10px",
//                       cursor: "pointer",
//                       backgroundColor: isSelected ? "lightblue" : "inherit",
//                       padding: "5px",
//                       borderRadius: "4px",
//                       flexWrap: "nowrap",
//                     }}
//                     oonClick={() => handleAttachment(attachment.url)}
//                   >
//                     <Checkbox
//                       checked={isSelected}
//                       onChange={() => handleAttachment(attachment.url)}
//                       color="primary"
//                       inputProps={{ "aria-label": "select attachment" }}
//                     />
//                     <InsertDriveFileIcon style={{ marginRight: "5px" }} />
//                     <Typography>{attachmentName}</Typography>
//                   </Box>
//                 );
//               }
//               return null;
//             })}
//         </Box>
//         <Button onClick={handleCallFileReader}>
//           Generate Sections
//         </Button>
//       </Box>
//     </Modal>
//   );
// };

const CustomOption = (props) => {
  const { data, innerRef, innerProps, isSelected } = props;

  return (
    <div ref={innerRef} {...innerProps} style={{ display: 'flex', alignItems: 'center' }}>
      <input type="checkbox" checked={isSelected} onChange={() => {}} />
      <span style={{ marginLeft: 10 }}>{data.label}</span>
    </div>
  );
};

export const AttachmentModal = (props) => {
  const {
    open,
    close,
    documentData,
    resourceLinks,
    handleAttachment,
    sectorData,
    defaultSector,
    selectedSectors,
    handleSectorFieldChange,
    // customStyles1,
    selectedFields,
    AiData,
    handleSelectFieldChange,
    sectionCreation,
    handleCallFileReader
  } = props;
  console.log("AiData",AiData)

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const customStyles1 = {
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#1976d2',
      color: 'white',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: 'white',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: 'white',
      ':hover': {
        backgroundColor: '#145a86',
        color: 'white',
      },
    }),
    control: (provided) => ({
      ...provided,
      height: '50px', // Fixed height for the Select control
      overflowY: 'auto', // Make it scrollable
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    container: (provided) => ({
      ...provided,
      width: windowWidth < 769 ? "100%" : '400px',
    }),
  };

  useEffect(() => {
    console.log("AttachmentModal", props);
  }, [props]);

  const isPDFAttachment = (attachmentName) => {
    return (
      attachmentName?.toLowerCase().endsWith(".pdf") ||
      attachmentName?.toLowerCase().endsWith(".docx")
    );
  };

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <Modal open={open} onClose={close}>
      <Box className="modal-container">
        <div className="header1">
          <h2 style={{ color: "#1976D2" }}>Attachments</h2>
          <HighlightOffIcon style={{ color: "red", cursor: "pointer" }} onClick={close} />
        </div>
        <Box className="modal-header" style={{ marginBottom: "10px" }}>
          <div className="title">
            <Typography variant="h5" component="div" className="modal-title">
              Documents List
            </Typography>
          </div>

          <div className="section-creation-container">
            <input type="text" value={defaultSector} disabled className="sector-input" />
          </div>
        </Box>
        <Box className="modal-header2">
          <div className="selectStyle">
            {/* <Select
              isMulti
              options={AiData}
              value={selectedFields}
              onChange={handleSelectFieldChange}
              styles={customStyles1}
              placeholder="Ai Sections"
              // components={{ Option: CustomOption }}
            /> */}

            <CheckboxesTags options={AiData.filter(obj => obj.value !== 'select-all')} 
            value={selectedFields} onChangeValue={handleSelectFieldChange}
              label="AI Sections" />
          </div>
          <div
            className="create-section-button"
          >
            <div className="onsmallDiv">
              <Typography variant="h5" component="div" className="modal-title">
                Documents List
              </Typography>
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={sectionCreation}
              className="smallDeviceStyle"
            >
              Create Sections
            </Button>
          </div>
        </Box>

        <Box className="document-list-container">
          {documentData &&
            documentData.map((attachment, index) => {
              const attachmentName = attachment?.linkname;
              if (isPDFAttachment(attachmentName)) {
                const isSelected = resourceLinks?.includes(attachment.url);
                return (
                  <Box
                    className={`document-row ${isSelected ? 'selected' : ''}`}
                    key={index}
                    onClick={() => handleAttachment(attachment.url)}
                  >
                    <Checkbox
                      checked={isSelected}
                      onChange={() => handleAttachment(attachment.url)}
                      color="primary"
                      inputProps={{ "aria-label": "select attachment" }}
                    />
                    <InsertDriveFileIcon className="document-icon" />
                    <Typography className="document-name">{attachmentName}</Typography>
                  </Box>
                );
              }
              return null;
            })}
        </Box>
        <Button className="generate-section-button smallDeviceStyle" variant="contained" color="primary" onClick={handleCallFileReader}>
          Generate Sections
        </Button>
      </Box>
    </Modal>
  );
};