import React, { useEffect } from "react";
import "./App.css";
import { ToastContainer } from "react-toastify";
import { Route, Switch, Redirect } from "react-router-dom";
import Home from "./components/Home";
import DashBoard from "./components/pages/DashBoardPage";
import TextEditorPage from "./components/pages/TextEditorPage";
import LoginForm from "./components/LoginForm";
import ForgotPassword from "./components/ForgotPasswordForm";
import RegisterForm from "./components/RegisterForm";
import AlertsPage from "./components/pages/AlertsPage";
import DocumentPreviewPage from "./components/pages/DocumentPreviewPage";
import TeamMembersPage from "./components/pages/TeamMembersPage"; 
import PricingPage from "./components/pages/PricingPage";
// import ContactUsPage from "./components/pages/ContactUsPage";
import FAQPage from "./components/pages/FAQPage";
import MyDocumentsPage from "./components/pages/MyDocumentsPage";
import MyDocumentsNewProjectPage from "./components/pages/MyDocumentsNewProjectPage";
// import AccountSettingsPage from "./components/pages/AccountSettingsPage";
import AccountSettingsPage from "./components/pages/AccountSettingsPage2";
import AdminDashBoard from "./components/pages/AdminDashboardPage";
import UserInvoice from "./components/pages/UserInvoice";
import ManageUserPage from "./components/pages/ManageUserPage";
import PaymentPage from "./components/pages/PaymentPage";
import ReviewRatingPage from "./components/pages/ReviewRatingPage";
import NewsletterPage from "./components/pages/NewsletterPage";
import ManageInvoicesPage from "./components/pages/ManageInvoicePage";
import NetworkDetector from "./components/HigherOrderComponents/NetworkDetector";
import SideNavBar from "./components/SideNavBar";
import ResetPassPage from "./components/pages/ResetPassPage";
import Logout from "./components/Logout";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Disclaimer from "./components/Disclaimer";
import TermsofService from "./components/TermsofService";
import Reports from "./components/pages/ReportsPage";
import DocTags from "./components/pages/DocTagsPage";
import Sample from "./components/SampleSocket";
import TeamCollaborationPage from "./components/pages/TeamCollaborationPage";
import PriceDetails from "./components/pages/PriceDetails";
import PaypalCheckOutButton from "./components/paypalButton";
import PaymentReport from "./components/PaymentReport";
// import Blogpage from "./components/Blogpage";
import SubAdminManagerUserPage from "./components/pages/SubAdminSideBarPage";
import Addsubcription from "./components/pages/Addsubcription";
import Blog from "./components/Blog";
import websiteForm from "./components/Forms/websiteForm"
import JournalForm from "./components/Forms/JournalForm"
import CitationPage from "./components/pages/CitationPage";
import CoAuthorPage from "./components/pages/CoAuthorPage";
import CoAuthorNewProjectPage from "./components/pages/CoAuthorNewProjectPage";
import CitationList from "./components/Forms/CitationList";
import Citationproj from "./components/Forms/Citationproj"
import BookForm from "./components/Forms/BookForm";
import CompareDocumentPage from './components/pages/CompareDocumentPage';
import ParaphrasePage from "./components/pages/ParaphrasePage";
import ErrorBoundary from "./components/ErrorBoundary"
import PlagiarismPage from "./components/pages/PlagiarismPage";
import TranslatorPage from "./components/pages/TranslatorPage";
 
import HomePriceDetails from "./components/HomePriceDetails";
import MyCitationsList from "./components/pages/MyCitationListPage";
import BlogSection from "./components/pages/BlogSectionPage";
import AfterRegistration from "./components/pages/AfterRegistrationPage";
import SummeriserPage from "./components/Forms/SummeriserPage";
import SafeRoute from './components/HigherOrderComponents/UserProtectedRoutes';
import AdminSafeRoute from './components/HigherOrderComponents/AdminProtectedRoutes';
import BlogAccessForPublic from "./components/pages/BlogAccessForPublicPage";
// import DocumentReport from "./components/DocumentReport"
import AdminBlog from "./components/pages/AdminBlogPage";
import CitationProjPage from "./components/pages/CitationProjPage";
import BlogsonLandingPage from "./components/pages/BlogsonLandingpage";
import CostumHomePage from "./components/pages/CostumHomePage";
import NewHome from "./components/NewHome"
import SocialMediaAuth from "./components/pages/SocialMediaAuthPage";
import ParaTwoPage from "./components/pages/ParaTwoPage";
import ContactUs from "./components/pages/ContactUsPages"
import BlogManagementPage from "./components/pages/BlogManagementPage";
import SEO from "./components/SEO";
import LoginForm2 from "./components/LoginForm2";
import RegisterForm2 from "./components/RegisterForm2";
import ForgotPasswordForm2 from "./components/ForgotPasswordForm2";
import ResetPass2 from "./components/ResetPass2";
import OTPVerification from "./components/OTPVerification";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import { useDispatch } from "react-redux";
// import { setCurrentPage } from "./redux/lastVisited/lastVisited.actions";
import SubAdminDashboardPage from "./components/pages/SubAdminDashboardPage";
import GrammarCheckPage from "./components/pages/GrammarCheckPage";
import SubAdminUserRequestPage from "./components/pages/SubAdminUserRequestPage";
import PromptsGeneratorPage from "./components/pages/PromptsGeneratorPage";
import SubAdminWorkFlowPage from "./components/pages/SubAdminWorkFlowPage";
import OppourtunityManagementPage from "./components/pages/OppourtunityManagementPage";
import DocumentManagementPage from "./components/pages/DocumentManagementPage";
import OrganizationPage from "./components/pages/OrganizationPage";
import ManageOrganizationPage from "./components/pages/ManageOrganizationPage";
import CommunicationHubPage from "./components/pages/CommunicationHubPage";
import UserRolesPage from "./components/pages/UserRolesPage";
import HelpAndSupportPage from "./components/pages/HelpAndSupportPage";
import OpportunitymgmtSubAdminPage from "./components/pages/OpportunitymgmtSubAdminPage";
import DocumentMgmtSubAdminPage from "./components/pages/DocumentMgmtSubAdminPage";
import UserRoleSubAdminPage from "./components/pages/UserRoleSubAdminPage";
import ComminicationHubSubadminPage from "./components/pages/ComminicationHubSubadminPage";
import HelpAndSupportsubAdminPage from "./components/pages/HelpAndSupportsubAdminPage";
import WorkflowAdministrationSubAdminPage from "./components/pages/WorkflowAdministrationSubAdminPage";
import SearchPage from "./components/pages/SearchPage";
import FilterOpportunityOrgPage from "./components/pages/FilterOpportunityOrgPage";
import featurelistscreen from "./components/pages/FeaturelistPage";
import AppFeaturelistscreen from "./components/pages/AppFeaturelistPage";
import SingleNoticeInfoPage from "./components/pages/SingleNoticeInfoPage";
import ManualOpportunityPage from "./components/pages/ManualOpportunityPage";
import { OpportunityManagementUpload } from "./components/pages/OppourtunityManagmentNewPage";
import OpportunityrfpPage from "./components/pages/OpportunityrfpPage";
import ProposalWritingPage from "./components/pages/ProposalWritingPage";import Zipfile from "./components/Zipfile";
import FarCauses from "./components/pages/FarCauses";
import FarCausePage from "./components/pages/FarCausePage";

import OpportunityManagementPage from "./components/pages/OpportunityManagementPage";
import ProposalCreationPage from "./components/pages/ProposalCreationPage";
import Farclause from "./components/Farclause";
import FARpage from "./components/pages/FARpage"; 
import TemplateGalleryPage from "./components/pages/TemplateGalleryPage"; 


function App() {
  // const history = useHistory()
  // const dispatch = useDispatch()
  // const unlisten = history.listen((location, action) => {
  //   if (location.pathname !== "/login" || location.pathname !== "/logout" || location.pathname !== "/" || location.pathname !== null) {
  //     return
  //   }
  //   // dispatch(setCurrentPage(location.pathname))
  //  let cp = sessionStorage.setItem("lastVisitedPage", location.pathname)
  //  console.log("cp",cp)
  //  console.log("hello", 1)
  // })
  // useEffect(() => {
  //   unlisten()
  // }, [history])
 
  return (
    <React.Fragment>
      <ToastContainer />
      <Switch>
        {/* <Tableuse/> */}
        <Route path="/" component={Home} exact />
        <Route path="/Summarizer" component={SummeriserPage} />
       
        <Route path="/blogeditor" component={BlogManagementPage} />
 
        {/* <Route exact path="/login" component={LoginForm} /> */}
        <Route exact path="/login" component={LoginForm2} />
        <Route path="/otpverification" component={OTPVerification} />
        {/* <Route path="/emailVerification/:confirmEmail/:token" component={LoginForm2} /> */}
        <Route path="/login/:loginParam" component={LoginForm2} />
        {/* <Route path="/forgotpassword" component={ForgotPassword} /> */}
        <Route path="/forgotpassword" component={ForgotPasswordForm2} />
        {/* <Route path="/resetpassword" component={ResetPassPage} /> */}
        <Route path="/resetpassword" component={ResetPassPage} />
        {/* <Route path="/signup" component={RegisterForm} /> */}
        <Route path="/signup" component={RegisterForm2} />
        <Route path="/pricing" component={PricingPage} />
        {/* <Route path="/contactus" component={ContactUsPage} /> */}
        <Route path="/faq" component={FAQPage} />
        <SafeRoute path="/collaboration" component={TeamCollaborationPage} />
        <SafeRoute path="/compareDocument" component={CompareDocumentPage} />
        <AdminSafeRoute
          path="/manageinvoices"
          component={ManageInvoicesPage}
        />
        <Route path="/logout" component={Logout} />
        <Route path='/socialMediaAuth' component={SocialMediaAuth} />
        <SafeRoute path="/invoices" component={UserInvoice} />
        <SafeRoute path="/pricedetails" component={PriceDetails} />
        <AdminSafeRoute
          path="/adminaccountsettings"
          component={AccountSettingsPage}
        />
        <SafeRoute
          path="/paraphrase"
          component={ParaphrasePage}
        />
        <SafeRoute path="/paratwo" component={ParaTwoPage} />
        <SafeRoute path="/plagiarism" component={PlagiarismPage} />
        <SafeRoute path="/translator" component={TranslatorPage} />
 
        <AdminSafeRoute
          path="/admindashboard"
          component={AdminDashBoard}
        />
        <AdminSafeRoute
          path="/addsubscription"
          component={NetworkDetector(Addsubcription)}
        />
        <AdminSafeRoute path="/manageuser" component={ManageUserPage} />
        <AdminSafeRoute path="/manageorganization" component={ManageOrganizationPage} />
        <SafeRoute
          path="/accountsettings"
          component={AccountSettingsPage}
        />
        <AdminSafeRoute path="/Newsletter" component={NewsletterPage} />
        <SafeRoute
          path="/mydocuments"
          component={MyDocumentsPage}
        />
 
        <AdminSafeRoute path="/Newsletter" component={NewsletterPage} />
        <SafeRoute
          path="/mynewdocuments"
          component={MyDocumentsNewProjectPage}
        />
 
        <SafeRoute
          path="/grammarcheck"
          component={GrammarCheckPage}
        />
 
        {/* <AdminSafeRoute
          path="/managereviews"
          component={ReviewRatingPage}
        /> */}
        {/* <AdminSafeRoute
          path="/adminBlogs"
          component={AdminBlog}
        /> */}
        <AdminSafeRoute
          path="/promptsGenerator"
          component={PromptsGeneratorPage}
        />
        <Route path="/privacypolicy" component={PrivacyPolicy} />
        <Route path="/disclaimer" component={Disclaimer} />
        <Route path="/termsofservice" component={TermsofService} />
        {/* <Route path="/mobilesidenav" component={MobileSidenav} /> */}
        <SafeRoute
          key="documentpreview"
          exact
          path="/documentpreview/:id"
          component={DocumentPreviewPage}
        />
        <SafeRoute
          key="blogpreview"
          exact
          path="/blogpreview/:id"
          component={DocumentPreviewPage}
        />
        <SafeRoute path="/subAdminDashboard" component={SubAdminDashboardPage} />
        <SafeRoute path="/subAdminManageUser" component={SubAdminManagerUserPage} />
        <SafeRoute path="/subAdminUserRequest" component={SubAdminUserRequestPage} />
        <SafeRoute path="/subAdminWorkFlow" component={SubAdminWorkFlowPage} />
        <SafeRoute
          exact
          path="/payment"
          component={PaymentPage}
        />
        <Route path="/homepricedetails" component={HomePriceDetails} />
        <SafeRoute path="/dashboard" component={DashBoard} />
        <SafeRoute path="/alerts" component={AlertsPage} />
        <Route
          path="/texteditor"
          component={TextEditorPage}
        />
        <SafeRoute
          path="/paymentreport"
          component={PaymentReport}
        />
 
        <SafeRoute path="/myteam" component={TeamMembersPage} />
        <SafeRoute path="/reports" component={Reports} />
        <SafeRoute path="/docTags" component={DocTags} />
        <SafeRoute path="/blogpage" component={BlogSection} />
        <SafeRoute path="/citation" component={CitationPage} />
        <SafeRoute path="/coauthor" component={CoAuthorPage} />
        <SafeRoute path="/coauthor-newproject" component={CoAuthorNewProjectPage} />
 
        <SafeRoute path="/citationList" component={MyCitationsList} />
        <SafeRoute path="/citationproj" component={CitationProjPage} />
        <SafeRoute path="/plagiarism" component={PlagiarismPage} />
        <Route path="/translator" component={TranslatorPage} />
        <Route path="/OppourtunityManagement" component={OppourtunityManagementPage} />
        <Route path="/documentManagement" component={DocumentManagementPage} />
        <Route path="/communicationHub" component={CommunicationHubPage} />
        <Route path="/userRole" component={UserRolesPage} />
        <Route path="/helpAndSupport" component={HelpAndSupportPage} />
        <Route path="/OppourtunityManagementSubadmin" component={OpportunitymgmtSubAdminPage} />
        <Route path="/OppourtunityManagementSubadminRedirect/:id" component={OpportunitymgmtSubAdminPage} />
        <Route path="/documentManagementSubadmin" component={DocumentMgmtSubAdminPage} />
        <Route path="/communicationHubSubadmin" compnent={ComminicationHubSubadminPage} />
        <Route path="/userroleSubadmin" component={UserRoleSubAdminPage} />
        <Route path="/helpAndSupportSubadmin" component={HelpAndSupportsubAdminPage} />
        <Route path="/workflow" component={WorkflowAdministrationSubAdminPage} />
        <Route path="/organization" component={OrganizationPage} />
        <Route path="/searchOpportunity" component={SearchPage} />
        <Route path="/filterOpportunityinOrg" component={FilterOpportunityOrgPage} />
        <Route path="/featuresList" component={featurelistscreen} />
        <Route path="/appfeatureRolelist" component={AppFeaturelistscreen} />
        <Route path="/farCauses" component={FarCausePage} />
        <Route path="/manualOpportunity" component={ManualOpportunityPage} />
        <Route path="/singleNoticePage" component={SingleNoticeInfoPage} />
        <Route path="/rfpManagement" component={OpportunityrfpPage} />
        <SafeRoute path="/contactUs" component={ContactUs} />
        <Route path='/public/blogpreview/:id' component={BlogAccessForPublic} />
        <Route path="/blog" component={BlogsonLandingPage} />
        <Route path="/Zipfile" component={Zipfile} />
        <Route path="/proposalWriting" component={ProposalWritingPage} />
        <Route path="/TemplateManagement" component={OpportunityManagementPage} />
        <Route path="/ProposalCreationPage" component={ProposalCreationPage} />
        <Route path="/TemplateGallery" component={TemplateGalleryPage} />

        {/* <Route path="/:id" component={BlogAccessForPublic} /> */}
        <Route path="/afterRegistration" component={AfterRegistration} />
        <AdminSafeRoute path="/costumHome" component={CostumHomePage} />
        <Route path="/newhome" component={NewHome} />
        <Route path="/FARclause" component={FARpage} />
        <Route path="/far" component={FARpage} />
        <Route path="/dfars" component={FARpage} />
        <Route path="/dfarspgi" component={FARpage} />
        <Route path="/:name" component={BlogAccessForPublic} />
        <Redirect to="/" />
      </Switch>
    </React.Fragment>
  );
}

 
export default App;