import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CheckboxesTags({ options, getOptionLabel,value, onChangeValue, label, placeholder }) {
  // const [value, setValue] = React.useState();
  const [inputValue, setInputValue] = React.useState('');

  // const [selectAllChecked, setSelectAllChecked] = React.useState(false);

  // React.useEffect(() => {
  //   if(value && options){
  //     setSelectAllChecked(value.length === options.length);
  //   }
  // }, [value, options])

  // const handleSelectAllChange = () => {
  //   if (selectAllChecked) {
  //     onChangeValue([]); // Deselect all
  //   } else {
  //     onChangeValue(options); // Select all
  //   }
  //   setSelectAllChecked(!selectAllChecked);
  // }

  return (
    <Autocomplete
      multiple
      limitTags={3}
      id="checkboxes-tags-demo"
      options={options}
      disableCloseOnSelect
      value={value}
        onChange={(event, newValue) => {
          console.log("checkboxescomponchange",event,newValue)
          onChangeValue(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          console.log("checkboxescomponInputChange",event,newInputValue)
          setInputValue(newInputValue);
        }}
      getOptionLabel={(option) => option.name || option.value}
      // getOptionLabel={getOptionLabel}
      renderOption={(props, option, { selected }) => {
        const { key, ...optionProps } = props;
        const isChecked = value.some(item => item.value === option.value || item.name === option.name);
        return (
          <li key={key} {...optionProps}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
              // checked={isChecked}
            />
            {option.name || option.value}
            {/* {getOptionLabel} */}
          </li>
        );
      }}
      style={{ width: 500 }}
      renderInput={(params) => (
        <TextField {...params} label={
          <span
            style={{ color: "black", fontWeight: "bold" }}
          >{label}</span>
        }
          placeholder={placeholder} />
      )}
      // renderTags={(value, getTagProps)=> [
      //   <Checkbox
      //     key="selectAll"
      //     icon={icon}
      //     checkedIcon={checkedIcon}
      //     style={{ marginRight: 8 }}
      //     checked={selectAllChecked}
      //     onChange={handleSelectAllChange}
      //   />,
      //   ...value.map((option, index) => (
      //     <span {...getTagProps({ index })} key={index}>
      //       {option.name || option.value}
      //     </span>
      //   )),
      // ]}
    />
  );
}